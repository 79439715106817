import { colors } from "vuetify/lib";

const state = {
  status: [
    {
      text: "Cancelled",
      value: "cancelled",
      sort: 1,
      complete: true,
      color: colors.red.base,
    },
    {
      text: "Not Started",
      value: "notStarted",
      sort: 2,
      complete: false,
      color: colors.grey.base,
    },
    {
      text: "In Progress",
      value: "inProgress",
      sort: 3,
      complete: false,
      color: colors.yellow.darken2,
    },
    {
      text: "Under Review",
      value: "review",
      sort: 4,
      complete: false,
      color: colors.blue.base,
    },
    {
      text: "Paused",
      value: "paused",
      sort: 5,
      complete: false,
      color: colors.deepPurple.base,
    },
    {
      text: "Complete",
      value: "complete",
      complete: true,
      sort: 6,
      color: colors.green.base,
    },
  ],
  priority: [
    { text: "Normal", value: "normal", sort: 1, color: "black" },
    { text: "High", value: "high", sort: 2, color: "orange" },
  ],
  taskTypes: [
    { text: "Call", value: "call", sort: 1, account: true },
    { text: "Meeting", value: "meeting", sort: 2, account: true },
    { text: "Dispensary Visit", value: "visit", sort: 3, account: true },
    { text: "LVM", value: "lvm", sort: 4, account: true },
    { text: "Email", value: "email", sort: 5, account: true },
    { text: "Text Message (SMS)", value: "sms", sort: 6, account: true },
    { text: "Social Media Message", value: "social_media", sort: 7 },
    { text: "Orders", value: "orders", sort: 8, account: true },
    { text: "Accounts Receivable (AR)", value: "ar", sort: 9, account: true },
    { text: "Marketing", value: "marketing", sort: 10 },
    { text: "Graphic Design/Creative", value: "creative", sort: 11 },
    { text: "Support", value: "support", sort: 12 },
    {
      text: "Scheduling/Logistics",
      value: "scheduling",
      sort: 13,
      account: true,
    },
    { text: "Admin", value: "admin", sort: 14 },
    { text: "Sourcing", value: "sourcing", sort: 15 },
    { text: "Manufacturing", value: "manufacturing", sort: 16 },
    { text: "Other", value: "other", sort: 17 },
  ],
  taskQuickNotes: [
    {
      text: "New Order",
      desc: "",
      type: ["call"],
    },
    {
      text: "Another quick note type",
      desc: "",
      type: ["call", "meeting"],
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getTaskStatus(state) {
    return state.status;
  },
  getTaskPriority(state) {
    return state.priority;
  },
  getTaskTypes(state) {
    return state.taskTypes;
  },
  getTaskQuickNotes(state) {
    return state.taskQuickNotes;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
