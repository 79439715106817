<template>
  <v-expansion-panels multiple flat v-model="Array.from([0, 1, 2])">
    <!-- Brand Details -->
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="text-capitalize font-weight-bold">brand</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form>
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="Status"
                :color="$vuetify.theme.themes.dark.primary"
                :value="getRightDrawer.data.status || '[Not Set]'"
                hide-details
                readonly
              >
                <template v-slot:prepend-inner>
                  <v-badge
                    v-if="getRightDrawer.data.statusColor"
                    inline
                    dot
                    :color="getRightDrawer.data.statusColor"
                  ></v-badge>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="Sub-Status"
                :color="$vuetify.theme.themes.dark.primary"
                :value="getRightDrawer.data.subStatus || '[Not Set]'"
                hide-details
                readonly
                :disabled="!getRightDrawer.data.subStatus"
              >
                <template v-slot:prepend-inner>
                  <v-badge
                    v-if="getRightDrawer.data.subStatus"
                    inline
                    dot
                    :color="getRightDrawer.data.subStatusColor"
                  ></v-badge>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="Payment Terms"
                :color="$vuetify.theme.themes.dark.primary"
                :value="getRightDrawer.data.paymentTerms"
                hide-details
                readonly
                :disabled="!getRightDrawer.data.paymentTerms"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="Credits"
                :color="$vuetify.theme.themes.dark.primary"
                :value="
                  getRightDrawer.data.brandMetrics?.metrics.credits | currency
                "
                hide-details
                readonly
                :disabled="
                  getRightDrawer.data.brandMetrics?.metrics.credits === 0
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="Account Manager"
                :color="$vuetify.theme.themes.dark.primary"
                :value="
                  getRightDrawer.data.brandMetrics?.staff.manager?.name
                    | capitalize
                "
                :hint="getRightDrawer.data.brandMetrics?.staff.manager.email"
                persistent-hint
                readonly
                :disabled="
                  getRightDrawer.data.brandMetrics?.staff.manager?.name
                    .length === 0
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="Account Opener"
                :color="$vuetify.theme.themes.dark.primary"
                :value="
                  getRightDrawer.data.brandMetrics?.staff.open?.name
                    | capitalize
                "
                :hint="getRightDrawer.data.brandMetrics?.staff.open.email"
                persistent-hint
                readonly
                :disabled="
                  getRightDrawer.data.brandMetrics?.staff.open?.name.length ===
                  0
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="Account Executive"
                :color="$vuetify.theme.themes.dark.primary"
                :value="
                  getRightDrawer.data.brandMetrics?.staff.executive?.name
                    | capitalize
                "
                :hint="getRightDrawer.data.brandMetrics?.staff.executive.email"
                persistent-hint
                readonly
                :disabled="
                  getRightDrawer.data.brandMetrics?.staff.executive?.name
                    .length === 0
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="Account Closer"
                :color="$vuetify.theme.themes.dark.primary"
                :value="
                  getRightDrawer.data.brandMetrics?.staff.close?.name
                    | capitalize
                "
                :hint="getRightDrawer.data.brandMetrics?.staff.close.email"
                persistent-hint
                readonly
                :disabled="
                  getRightDrawer.data.brandMetrics?.staff.close?.name.length ===
                  0
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!-- Account Details -->
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="text-capitalize font-weight-bold">account</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form>
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="Storefront Name"
                :color="$vuetify.theme.themes.dark.primary"
                persistent-hint
                :value="getRightDrawer.data.account?.name | capitalize"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="License Name"
                :color="$vuetify.theme.themes.dark.primary"
                :value="getRightDrawer.data.account.dba | capitalize"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="License Number"
                :color="$vuetify.theme.themes.dark.primary"
                :value="
                  getRightDrawer.data.account.license.number.toUpperCase()
                "
                persistent-hint
                :hint="getRightDrawer.data.licenseExpirationHint"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="Store Type"
                :color="$vuetify.theme.themes.dark.primary"
                :value="
                  getRightDrawer.data.account.storeTypeDef.text | capitalize
                "
                hide-details
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                label="Delivery Preferences"
                :color="$vuetify.theme.themes.dark.primary"
                :hint="getRightDrawer.data.deliveryPreferencesHint"
                outlined
                persistent-hint
                no-resize
                :value="getRightDrawer.data.account.deliveryPreferences.notes"
                hide-details
                readonly
                :disabled="
                  getRightDrawer.data.account.deliveryPreferences.notes
                    .length === 0
                "
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-checkbox
                label="Same Shipping/Billing"
                :color="$vuetify.theme.themes.dark.success"
                v-model="getRightDrawer.data.account?.flags.sameBilling"
                hide-details
                readonly
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="LeafLink ID"
                :color="$vuetify.theme.themes.dark.primary"
                :value="getRightDrawer.data.account.externalIDs.leaflink"
                hide-details
                readonly
                :disabled="!getRightDrawer.data.account.externalIDs.leaflink"
              ></v-text-field>
            </v-col>
            <!-- Shipping -->
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="Shipping Address"
                :color="$vuetify.theme.themes.dark.primary"
                :value="
                  getRightDrawer.data.account.shippingAddress | capitalize
                "
                hide-details
                readonly
                :disabled="
                  getRightDrawer.data.account.shippingAddress.length === 0
                "
              ></v-text-field>
            </v-col>
            <!-- Billing -->
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="Billing Address"
                :color="$vuetify.theme.themes.dark.primary"
                :value="getRightDrawer.data.account.billingAddress | capitalize"
                hide-details
                readonly
                :disabled="
                  getRightDrawer.data.account.billingAddress.length === 0
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                label="Email"
                :color="$vuetify.theme.themes.dark.primary"
                :value="getRightDrawer.data.account.email"
                hide-details
                readonly
                :disabled="!getRightDrawer.data.account.email"
              >
                <template v-slot:append-outer>
                  <v-btn
                    small
                    rounded
                    :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
                    v-if="getRightDrawer.data.account.email.length > 0"
                    @click="
                      viewResource('email', getRightDrawer.data.account.email)
                    "
                  >
                    <span class="mr-1">email</span>
                    <v-icon>{{ $vuetify.icons.values.email }}</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                label="Phone Number"
                type="tel"
                :color="$vuetify.theme.themes.dark.primary"
                :value="getRightDrawer.data.account.phoneNumber | phoneNumber"
                hide-details
                readonly
                :disabled="!getRightDrawer.data.account.phoneNumber"
              >
                <template v-slot:append-outer>
                  <v-btn
                    small
                    rounded
                    :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
                    v-if="getRightDrawer.data.account.phoneNumber"
                    @click="
                      viewResource(
                        'phone',
                        getRightDrawer.data.account.phoneNumber
                      )
                    "
                  >
                    <span class="mr-1">call</span>
                    <v-icon>{{ $vuetify.icons.values.phoneNumber }}</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                label="Website"
                :color="$vuetify.theme.themes.dark.primary"
                :value="getRightDrawer.data.account.website"
                hide-details
                readonly
                :disabled="!getRightDrawer.data.account.website"
              >
                <template v-slot:append-outer>
                  <v-btn
                    small
                    rounded
                    :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
                    v-if="getRightDrawer.data.account.website.length > 0"
                    @click="
                      viewResource('url', getRightDrawer.data.account.website)
                    "
                  >
                    <span class="mr-1">view</span>
                    <v-icon>mdi-web</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!-- System -->
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="text-capitalize font-weight-bold">system</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <template v-if="getRightDrawer.data.brandMetrics">
          <v-form>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  label="Created"
                  :color="$vuetify.theme.themes.dark.primary"
                  :value="
                    getRightDrawer.data.brandMetrics?.created?.seconds
                      | moment('MM/DD/YYYY @ hh:mm A')
                  "
                  persistent-hint
                  :hint="
                    `created by: ${getRightDrawer.data.brandMetrics?.createdBy.name.first} ${getRightDrawer.data.brandMetrics?.createdBy.name.last}`
                      | capitalize
                  "
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  label="Updated"
                  :color="$vuetify.theme.themes.dark.primary"
                  :value="
                    getRightDrawer.data.brandMetrics?.updated?.seconds
                      | moment('MM/DD/YYYY @ hh:mm A')
                  "
                  persistent-hint
                  :hint="
                    `updated by: ${getRightDrawer.data.brandMetrics?.lastUpdated.name.first} ${getRightDrawer.data.brandMetrics?.lastUpdated.name.last}`
                      | capitalize
                  "
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  label="Viewed"
                  :color="$vuetify.theme.themes.dark.primary"
                  :value="
                    getRightDrawer.data.brandMetrics.viewed
                      ? $options.filters.moment(
                          getRightDrawer.data.brandMetrics.viewed?.seconds *
                            1000,
                          'MM/DD/YYYY @ hh:mm A'
                        )
                      : '-'
                  "
                  persistent-hint
                  :hint="
                    getRightDrawer.data.brandMetrics.viewed
                      ? $options.filters.capitalize(
                          `last viewed by: ${getRightDrawer.data.lastViewed}`
                        )
                      : 'This account hasn\'t been viewed yet'
                  "
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "AccountPreview",
  data: () => ({}),
  methods: {
    ...mapActions(["_rightDrawer"]),
    /* API */
    // GET
    // POST
    /* Main */
    /* Misc */
    viewResource(type, value) {
      switch (type) {
        case "phone":
          window.open(`tel:${value}`);
          break;
        case "email":
          window.open(`mailto:${value}`);
          break;
        case "url":
          window.open(value, "_blank");
          break;
      }
    },
    loadAddress() {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
          this.deliveryAddress
        )}`,
        "_blank"
      );
    },
  },
  computed: {
    ...mapGetters([
      "getAppName",
      "getUser",
      "getRightDrawer",
      "getProductVisibility",
      "getStrainTypes",
    ]),
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
  },
};
</script>
