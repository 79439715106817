<template>
  <v-form>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels flat v-model="payload.panels.expenses">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-toolbar-title class="text-capitalize"
                >line items</v-toolbar-title
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="response.headers.expenses"
                loading-text="Loading Line Items..."
                :items="getRightDrawer.data.expenses"
                no-data-text="No Line Items added"
                sort-by="name"
                group-by="state"
                hide-default-footer
                :items-per-page="-1"
                mobile-breakpoint="0"
              >
                <template
                  v-slot:group.header="{
                    group,
                    items,
                    isOpen,
                    toggle,
                    remove,
                    headers,
                  }"
                >
                  <GroupByHeaderRow
                    :headers="headers"
                    :group="group"
                    :items="items"
                    :isOpen="isOpen"
                    :showRemoveIcon="false"
                    @toggle="toggle"
                    @remove="remove"
                  />
                </template>
                <template v-slot:item.number="{ index }">
                  <span>{{ index + 1 }}</span>
                </template>
                <template v-slot:item.name="{ item }">
                  <span>{{ item.name }}</span>
                </template>
                <template v-slot:item.state="{ item }">
                  <span class="text-capitalize">{{ item.state }}</span>
                </template>
                <template v-slot:item.location="{ item }">
                  <span class="text-capitalize">{{ item.location }}</span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span>{{ item.amount | currency }}</span>
                </template>
                <template v-slot:item.qty="{ item }">
                  <span>{{ item.qty | addComma }}</span>
                </template>
                <template v-slot:item.total="{ item }">
                  <span>{{ (item.amount * item.qty) | currency }}</span>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Brand"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.brand.name | capitalize"
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Vendor"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.vendor | capitalize"
          hide-details
          readonly
        ></v-text-field>
      </v-col>

      <v-col v-if="getRightDrawer.data.relatedID" cols="12" md="12" sm="12">
        <v-text-field
          label="Project"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            getRightDrawer.data.relatedID
              ? 'Click to view'
              : 'No associated Project'
          "
          hide-details
          readonly
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-textarea
          label="Notes"
          outlined
          background-color="grey lighten-3"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.notes"
          hide-details
          readonly
          :disabled="getRightDrawer.data.notes.length === 0"
        ></v-textarea>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-text-field
          label="Start Date"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            getRightDrawer.data.date.started.seconds | moment('MM/DD/YYYY')
          "
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-text-field
          label="Payment Due Date"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.date.due.seconds | moment('MM/DD/YYYY')"
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-text-field
          label="Ship Date"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            getRightDrawer.data.date.ship
              ? moment(getRightDrawer.data.date.ship.seconds * 1000).format(
                  'MM/DD/YYYY'
                )
              : ''
          "
          hide-details
          readonly
          :disabled="!getRightDrawer.data.date.ship"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-text-field
          label="Received Date"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            getRightDrawer.data.date.received
              ? moment(getRightDrawer.data.date.received.seconds * 1000).format(
                  'MM/DD/YYYY'
                )
              : ''
          "
          hide-details
          readonly
          :disabled="!getRightDrawer.data.date.received"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-text-field
          label="Sub-total"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            getRightDrawer.data.expenses
              .map((expense) => expense.amount * expense.qty)
              .reduce((a, b) => a + b, 0) | currency
          "
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-text-field
          label="Shipping"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.balance.shipping | currency"
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-text-field
          label="Balance Due"
          :color="$vuetify.theme.themes.dark.primary"
          :background-color="
            getRightDrawer.data.balance.due === 0 ? '' : 'grey lighten-3'
          "
          :value="
            (getRightDrawer.data.balance.shipping +
              getRightDrawer.data.expenses
                .map((expense) => expense.amount * expense.qty)
                .reduce((a, b) => a + b, 0) -
              getRightDrawer.data.deposits
                .map((deposit) => deposit.amount)
                .reduce((a, b) => a + b, 0))
              | currency
          "
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-text-field
          label="Total"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            (getRightDrawer.data.balance.shipping +
              getRightDrawer.data.expenses
                .map((expense) => expense.amount * expense.qty)
                .reduce((a, b) => a + b, 0))
              | currency
          "
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Internal Invoice #"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.number.internal.toUpperCase()"
          hide-details
          readonly
          :disabled="getRightDrawer.data.number.internal.length === 0"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="External Invoice #"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.number.external.toUpperCase()"
          :disabled="getRightDrawer.data.number.external.length === 0"
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Created"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.created | moment('MM/DD/YYYY @ hh:mm A')"
          :hint="
            `created by: ${getRightDrawer.data.createdBy.name.first} ${getRightDrawer.data.createdBy.name.last}`
              | capitalize
          "
          persistent-hint
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Updated"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.updated | moment('MM/DD/YYYY @ hh:mm A')"
          :hint="
            `last updated: ${getRightDrawer.data.lastUpdated.name.first} ${getRightDrawer.data.lastUpdated.name.last}`
              | capitalize
          "
          persistent-hint
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { VExpansionPanels } from "vuetify/lib";

export default {
  name: "CustomOrderPreview",
  data: () => ({
    response: {
      headers: {
        expenses: [
          {
            text: "#",
            value: "number",
            sortable: false,
          },
          {
            text: "Name",
            value: "name",
          },
          {
            text: "State",
            value: "state",
          },
          {
            text: "Location",
            value: "location",
          },
          {
            text: "Cost",
            value: "amount",
          },
          {
            text: "QTY",
            value: "qty",
          },
          {
            text: "Total",
            value: "total",
          },
        ],
      },
    },
    payload: {
      panels: {
        expenses: 0,
      }
    }
  }),
  methods: {
    ...mapActions(["_rightDrawer"]),
    /* API */
    // GET
    // POST
    /* Main */
    /* Misc */
  },
  computed: {
    ...mapGetters([
      "getAppName",
      "getUser",
      "getRightDrawer",
      "getProductVisibility",
      "getStrainTypes",
    ]),
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
  },
};
</script>
