<template>
  <v-form>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels flat multiple v-model="Array.from([0])">
          <v-expansion-panel>
            <v-expansion-panel-header class="px-3">
              <v-toolbar-title>Products</v-toolbar-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="response.headers.vendorOrderProducts"
                :items="getRightDrawer.data.fullProducts"
                :items-per-page="-1"
                sort-by="name"
                no-data-text="No Products"
                hide-default-footer
                mobile-breakpoint="0"
              >
                <template v-slot:item.number="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.name="{ item }">
                  <span class="text-capitalize">{{ item.name }}</span>
                </template>
                <template v-slot:item.sku="{ item }">
                  <span class="text-uppercase">{{ item.sku }}</span>
                </template>
                <template v-slot:item.qty="{ item }">
                  <span v-if="!item.custom">{{ item.qty | addComma }}</span>
                  <span v-else>{{ "custom" | capitalize }}</span>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-text-field
          label="Master Order"
          :color="$vuetify.theme.themes.dark.primary"
          :value="`Click 'view' to see all`"
          hide-details
          readonly
        >
          <template v-slot:append-outer>
            <v-btn
              small
              rounded
              :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
              @click="traverse('masterOrder-view')"
            >
              <span class="mr-1">view</span>
              <v-icon>{{ $vuetify.icons.values.view }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Brand"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.brand.name | capitalize"
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Product"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.productCategory | capitalize"
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Shipping Location"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            getRightDrawer.data.location.shippingLocation.name | capitalize
          "
          :hint="
            `${getRightDrawer.data.location.country.name}, ${
              getRightDrawer.data.location.state.name
            } (${getRightDrawer.data.location.state.abbreviation.toUpperCase()})`
              | capitalize
          "
          persistent-hint
          readonly
        >
          <template v-slot:append-outer>
            <v-btn
              small
              rounded
              :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
              @click="traverse('location-view')"
            >
              <span class="mr-1">view</span>
              <v-icon>{{ $vuetify.icons.values.view }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Order Date"
          :value="getRightDrawer.data.date | moment('MM/DD/YYYY')"
          :color="$vuetify.theme.themes.dark.primary"
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-textarea
          label="Notes"
          outlined
          :color="$vuetify.theme.themes.dark.primary"
          hide-details
          readonly
          :no-resize="getRightDrawer.data.notes.length === 0"
          :value="getRightDrawer.data.notes"
          :disabled="getRightDrawer.data.notes.length === 0"
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Total Cost"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            $options.filters.currency(
              getRightDrawer.data.invoices
                .map((invoice) => invoice.total)
                .reduce((a, b) => a + b, 0)
            )
          "
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Balance Due"
          :color="$vuetify.theme.themes.dark.primary"
          :background-color="
            getRightDrawer.data.invoices
              .map((invoice) => invoice.due)
              .reduce((a, b) => a + b, 0) === 0
              ? 'green lighten-3'
              : 'grey lighten-3'
          "
          :value="
            $options.filters.currency(
              getRightDrawer.data.invoices
                .map((invoice) => invoice.due)
                .reduce((a, b) => a + b, 0)
            )
          "
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-text-field
          label="Internal Invoice #"
          :background-color="
            getRightDrawer.data.number?.internal?.length > 0
              ? ''
              : 'yellow lighten-3'
          "
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.number?.internal?.toUpperCase()"
          hide-details
          readonly
          :disabled="getRightDrawer.data.number?.internal?.length === 0"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Created"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.created | moment('MM/DD/YYYY @ hh:mm A')"
          :hint="
            `created by: ${getRightDrawer.data.createdBy.name.first} ${getRightDrawer.data.createdBy.name.last}`
              | capitalize
          "
          persistent-hint
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Updated"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.updated | moment('MM/DD/YYYY @ hh:mm A')"
          :hint="
            `last updated: ${getRightDrawer.data.lastUpdated.name.first} ${getRightDrawer.data.lastUpdated.name.last}`
              | capitalize
          "
          persistent-hint
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "VendorOrderPreview",
  data: () => ({
    response: {
      headers: {
        vendorOrderProducts: [
          { text: "#", value: "number", sortable: false },
          { text: "Name", value: "name" },
          { text: "SKU", value: "sku" },
          { text: "Full QTY/Custom QTY?", value: "qty" },
        ],
      },
    },
  }),
  methods: {
    ...mapActions(["_rightDrawer"]),
    /* API */
    // GET
    // POST
    /* Main */
    async traverse(action, params) {
      let link;
      switch (action) {
        case "masterOrder-view":
          this.$router.push({
            name: "vendorOrder-master-view",
            params: {
              id: this.getRightDrawer.data.relatedID,
            },
          });
          break;
        case "location-view":
          link = this.$router.resolve({
            name: "locations-view",
            params: {
              id: this.getRightDrawer.data.location.shippingLocation.id,
            },
          });
          window.open(link.href, "_blank");
          break;
      }
    },
    /* Misc */
  },
  computed: {
    ...mapGetters([
      "getAppName",
      "getUser",
      "getRightDrawer",
      "getProductVisibility",
      "getStrainTypes",
    ]),
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
  },
};
</script>
