var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"px-3"},[_c('v-toolbar-title',[_vm._v("Preparation")])],1),_c('v-expansion-panel-content',[(_vm.getRightDrawer.data.flags.liveTraining)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Training Type","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("capitalize")(_vm.getRightDrawer.data.trainingType),"hide-details":"","readonly":""}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Estimated Budtenders","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("addComma")(_vm.getRightDrawer.data.preparation.amounts.budtenders),"hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Providing Meal?","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("capitalize")(_vm.getRightDrawer.data.preparation.meal),"hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.getRightDrawer.data.preparation.meal === 'yes')?_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.themes.dark.success}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.submit))]):_vm._e()]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Meal Budget","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.preparation.meal !== 'no'
                    ? new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(_vm.getRightDrawer.data.preparation.amounts.meal)
                    : 'No meal being provided',"hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Providing Swag?","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("capitalize")(_vm.getRightDrawer.data.preparation.swag),"hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.getRightDrawer.data.preparation.swag === 'yes')?_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.themes.dark.success}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.submit))]):_vm._e()]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Swag Budget","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.preparation.swag !== 'no'
                    ? _vm.getRightDrawer.data.preparation.amounts.swag
                    : 'No swag being provided',"hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Samples sent?","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.flags.sampleStatus,"hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.getRightDrawer.data.preparation.samples === 'yes')?_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.themes.dark.success}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.submit))]):_vm._e()]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Sample Budget","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.preparation.samples !==
                  'samples_received'
                    ? _vm.getRightDrawer.data.preparation.amounts.samples
                    : 'Received before training',"hide-details":"","readonly":""}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Account","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("capitalize")(_vm.getRightDrawer.data.account.name),"hint":_vm._f("capitalize")(('Account Executive: ' +
            _vm.getRightDrawer.data.account.accountExecutive.name)),"persistent-hint":"","readonly":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{style:(`background: ${_vm.$vuetify.theme.themes.dark.review}; color: ${_vm.$vuetify.theme.themes.dark.secondary};`),attrs:{"small":"","rounded":""},on:{"click":function($event){return _vm.traverse('account-view')}}},[_c('span',{staticClass:"mr-1"},[_vm._v("view")]),_c('v-icon',[_vm._v(_vm._s(_vm.$vuetify.icons.values.view))])],1)]},proxy:true}])})],1),(_vm.getRightDrawer.data.flags.liveTraining)?_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Trainer","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("capitalize")(`${_vm.getRightDrawer.data.trainer}`),"hide-details":"","readonly":""}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":!_vm.getRightDrawer.data.flags.liveTraining ? 6 : 12,"sm":"12"}},[_c('v-text-field',{attrs:{"label":"Date","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.dateForTraining(_vm.getRightDrawer.data),"hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":!_vm.getRightDrawer.data.flags.liveTraining ? 6 : 12,"sm":"12"}},[_c('v-text-field',{attrs:{"label":"Training Format","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.flags.liveTraining
            ? this.getBudtenderTrainingFormats.find(
                (type) => _vm.getRightDrawer.data.format === type.text
              ).text
            : 'Offline',"hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Address","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("capitalize")(`${_vm.getRightDrawer.data.account.location.address}, ${
            _vm.getRightDrawer.data.account.location.city
          }, ${_vm.getRightDrawer.data.account.location.state.abbreviation.toUpperCase()} ${
            _vm.getRightDrawer.data.account.location.zipCode
          }`),"hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{style:(`background: ${_vm.$vuetify.theme.themes.dark.review}; color: ${_vm.$vuetify.theme.themes.dark.secondary};`),attrs:{"small":"","rounded":""},on:{"click":function($event){return _vm.traverse('address')}}},[_c('span',{staticClass:"mr-1"},[_vm._v("map")]),_c('v-icon',[_vm._v(_vm._s(_vm.$vuetify.icons.values.map))])],1)]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"label":"Notes","outlined":"","no-resize":"","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.notes,"hide-details":"","readonly":"","disabled":_vm.getRightDrawer.data.notes.length === 0}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }