<template>
  <th :colspan="headers.length">
    <div class="d-flex align-center">
      <v-btn
        icon
        small
        outlined
        rounded
        @click="toggle"
        style="border-color: rgba(0, 0, 0, 0.12) !important"
      >
        <v-icon small :color="$vuetify.theme.themes.dark.primary"
          >mdi-arrow-{{ isOpen ? "up" : "down" }}</v-icon
        >
      </v-btn>
      <span class="mx-2 text-subtitle-2 text-capitalize font-weight-bold">
        {{ group }}
        <span class="text--secondary">
          ({{
            $options.filters.addComma(totalItems ? totalItems : items.length)
          }})
        </span>
      </span>
      <v-btn text outlined rounded small v-if="showRemoveIcon" @click="remove">
        <v-icon small class="mr-2">{{ $vuetify.icons.values.undo }}</v-icon>
        ungroup
      </v-btn>
    </div>
  </th>
</template>

<script>
// Libraries
import { mapGetters } from "vuex";

// Components

export default {
  name: "GroupByHeaderRow",
  data() {
    return {};
  },
  props: {
    totalItems: {
      // For custom amount value
      default: null,
      type: Number,
    },
    headers: {
      default: [],
      type: Array,
    },
    group: {
      default: "",
      type: String,
    },
    items: {
      default: [],
      type: Array,
    },
    isOpen: {
      default: true,
      type: Boolean,
    },
    showRemoveIcon: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    /* API */
    // GET
    // POST
    /* */
    toggle() {
      this.$emit("toggle");
    },
    remove() {
      this.$emit("remove");
    },
  },
  computed: {
    ...mapGetters([
      // Main
      "getAppName",
      "getUser",
    ]),
  },
};
</script>
