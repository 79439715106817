import { colors } from "vuetify/lib";

const state = {
  discountTypes: [
    {
      text: "Dollar Amount",
      value: "dollar",
      icon: "mdi-currency-usd",
    },
    {
      text: "Percentage",
      value: "percentage",
      icon: "mdi-percent",
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getDiscountTypes(state) {
    return state.discountTypes;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
