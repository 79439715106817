<template>
  <v-form>
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Status"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.status | capitalize"
          hide-details
        >
          <template v-slot:prepend-inner>
            <v-badge
              inline
              dot
              :color="getRightDrawer.data.statusColor"
            ></v-badge>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Ticket #"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.ticket | addComma"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col v-if="getRightDrawer.data.relatedTo" cols="12" md="12" sm="12">
        <v-text-field
          label="Related To"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.relatedTo.name | capitalize"
          hide-details
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Priority"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.priority"
          hide-details
        >
          <template v-slot:prepend>
            <v-icon
              :color="$vuetify.theme.themes.dark.error"
              v-if="getRightDrawer.data.priority.toLowerCase() === 'high'"
              >mdi-fire</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Type"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.type | capitalize"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Subject"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.subject"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
          label="Additional Details"
          outlined
          no-resize
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.notes"
          hide-details
          :disabled="getRightDrawer.data.notes.length === 0"
        ></v-textarea>
      </v-col>
      <v-col cols="6" md="6">
        <v-text-field
          label="Created"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.created | moment('MM/DD/YYYY @ hh:mm A')"
          persistent-hint
          :hint="`created by: ${getRightDrawer.data?.createdBy}` | capitalize"
          readonly
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="12">
        <v-text-field
          label="Updated"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.updated | moment('MM/DD/YYYY @ hh:mm A')"
          persistent-hint
          :hint="
            `last updated: ${getRightDrawer.data?.lastUpdated}` | capitalize
          "
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "SupportTicketPreview",
  data: () => ({}),
  methods: {
    ...mapActions(["_rightDrawer"]),
    /* API */
    // GET
    // POST
    /* Main */
    /* Misc */
  },
  computed: {
    ...mapGetters(["getAppName", "getUser", "getRightDrawer"]),
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
  },
};
</script>
