const state = {
  productComponentTypes: ["hardware", "packaging"],
  productVisibility: [
    {
      text: "Public",
      value: "public",
      icon: "mdi-earth",
      color: "#3F51B5",
      accentColor: "#E8EAF6",
      desc: "This category is visible to sales reps and buyers",
    },
    {
      text: "Internal",
      value: "internal",
      icon: "mdi-badge-account-horizontal",
      color: "#000000",
      accentColor: "#EEEEEE",
      desc: "This category is visible to sales reps and hidden from buyers",
    },
    {
      text: "Hidden",
      value: "hidden",
      icon: "mdi-eye-off",
      color: "#D50000",
      accentColor: "#FFEBEE",
      desc: "This category is not visible to sales reps or buyers",
    },
  ],
  strainTypes: [
    {
      text: "Sativa",
      value: "sativa",
    },
    {
      text: "Indica",
      value: "indica",
    },
    {
      text: "Hybrid",
      value: "hybrid",
    },
  ],
  productFamilies: [
    {
      text: "Vapes",
      value: "vapes",
    },
    {
      text: "Concentrates",
      value: "concentrates",
    },
    {
      text: "Edibles",
      value: "edibles",
    },
    {
      text: "Flower",
      value: "flower",
    },
    {
      text: "Pre-Rolls",
      value: "preRolls",
    },
    {
      text: "Topicals",
      value: "topicals",
    },
    {
      text: "Accessories",
      value: "accessories",
    },
    {
      text: "NOT SET",
      value: null,
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getProductComponentTypes(state) {
    return state.productComponentTypes;
  },
  getProductVisibility(state) {
    return state.productVisibility;
  },
  getStrainTypes(state) {
    return state.strainTypes;
  },
  getProductFamilies(state) {
    return state.productFamilies;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
