var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","md":"12","sm":"12"}},[(!_vm.getRightDrawer.data.img)?_c('v-avatar',{staticStyle:{"border":"solid 1px #cccccc !important"},attrs:{"color":"grey lighten-3","size":"120","tile":""}},[_c('span',{staticClass:"grey--text text--darken-2 text-subtitle-2 px-4"},[_vm._v("No Image Uploaded")])]):_c('v-avatar',{staticStyle:{"border":"solid 1px #cccccc"},attrs:{"size":"120","tile":""}},[_c('v-img',{attrs:{"contain":"","src":_vm.getRightDrawer.data.imgURL,"alt":`${_vm.$options.filters.capitalize(
            _vm.getRightDrawer.data.name
          )} Product Image`},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey"}})],1)]},proxy:true}])})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Product Name","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("capitalize")(_vm.getRightDrawer.data.name),"readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"SKU","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.sku.toUpperCase(),"persistent-hint":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"label":"Product Description","outlined":"","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.desc,"readonly":"","disabled":!_vm.getRightDrawer.data.desc || _vm.getRightDrawer.data.desc?.length === 0}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Product Color","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.color,"readonly":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-badge',{attrs:{"inline":"","dot":"","color":_vm.getRightDrawer.data.color}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Product Visibility","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getProductVisibility.find(
            (option) => option.value === _vm.getRightDrawer.data.visibility
          ).text,"hint":_vm.getRightDrawer.data.visibility === null
            ? ''
            : _vm.descriptionForVisibilityStatus(
                _vm.getRightDrawer.data.visibility,
                'product'
              ),"persistent-hint":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Strain Type","color":_vm.$vuetify.theme.themes.dark.primary,"value":!_vm.getRightDrawer.data.strainType
            ? ''
            : _vm.getStrainTypes.find(
                (option) => option.value === _vm.getRightDrawer.data.strainType
              ).text,"readonly":"","disabled":!_vm.getRightDrawer.data.strainType}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Created","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("moment")(_vm.getRightDrawer.data.created.seconds,'MM/DD/YYYY @ hh:mm A'),"hint":_vm._f("capitalize")(`created by: ${_vm.getRightDrawer.data.createdBy}`),"persistent-hint":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Updated","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("moment")(_vm.getRightDrawer.data.updated.seconds,'MM/DD/YYYY @ hh:mm A'),"persistent-hint":"","hint":_vm._f("capitalize")(`updated by: ${_vm.getRightDrawer.data.lastUpdated}`),"readonly":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }