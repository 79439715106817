<script lang="ts">
import Vue from "vue";
//@ts-ignore
import VTooltip from "vuetify/lib/components/VTooltip/VTooltip";

// Create Base Mixins and Define Custom Properties
const base = Vue.extend({ mixins: [VTooltip] });
export default base.extend({
  name: "DelayedTooltip",
  props: {
    closeDelay: {
      type: [Number, String],
      default: 0,
    },
    disabled: Boolean,
    openDelay: {
      type: [Number, String],
      default: 250, // 250ms default delay
    },
    openOnHover: {
      type: Boolean,
      default: true,
    },
    openOnFocus: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: String,
      default: "span",
    },
    transition: String,
  },
});
</script>
