// Config
import firebaseConfig from "./config";
// Services
import { initializeApp, getApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
  updateProfile,
  GoogleAuthProvider,
  linkWithPopup,
  updateEmail,
  unlink,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  onSnapshot,
  getDocs,
  getDoc,
  documentId,
  doc,
  FieldPath,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  or,
  and,
  limit,
  orderBy,
  serverTimestamp,
  Timestamp,
  FieldValue,
  runTransaction,
  writeBatch,
  deleteField,
  increment,
  arrayUnion,
  arrayRemove,
  startAt,
  startAfter,
  GeoPoint,
  getCountFromServer,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadString,
  getDownloadURL,
  getMetadata,
  getBytes,
  deleteObject,
} from "firebase/storage";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config";
// import {
//   getMessaging,
//   getToken,
//   onMessage,
//   deleteToken,
// } from "firebase/messaging";

const app = initializeApp(firebaseConfig);

const auth = getAuth();
const firestore = getFirestore();
const storage = getStorage();
const functions = getFunctions(getApp());
const remoteConfig = getRemoteConfig();
// const messaging = getMessaging(app);

if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

// onMessage(messaging, (payload) => {
//   const notificationTitle = payload.notification.title;
//   const notificationOptions = {
//     body: payload.notification.body,
//     click_action: payload.notification?.fcmOptions?.link || ""
//   };

//   new Notification(notificationTitle, notificationOptions)
// })

export {
  // Auth
  auth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
  updateProfile,
  GoogleAuthProvider,
  linkWithPopup,
  updateEmail,
  unlink,
  // Firestore
  firestore,
  collection,
  onSnapshot,
  getDocs,
  getDoc,
  doc,
  FieldPath,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  or,
  and,
  limit,
  orderBy,
  serverTimestamp,
  Timestamp,
  FieldValue,
  documentId,
  runTransaction,
  writeBatch,
  deleteField,
  increment,
  arrayUnion,
  arrayRemove,
  startAt,
  startAfter,
  GeoPoint,
  getCountFromServer,
  // Storage
  storage,
  ref,
  uploadBytes,
  uploadString,
  getDownloadURL,
  getMetadata,
  getBytes,
  deleteObject,
  // Functions
  functions,
  connectFunctionsEmulator,
  httpsCallable,
  // Remote Config
  remoteConfig,
  fetchAndActivate,
  getValue,
  // Messaging
  // messaging,
  // getMessaging,
  // getToken,
  // onMessage,
  // deleteToken
};
