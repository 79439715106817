import colors from "vuetify/lib/util/colors";

const state = {
  originalInventory: [],
  reconcile: [],
  workOrderProducts: [],
  status: [
    {
      text: "complete",
      value: 1,
      color: colors.green.base,
    },
    {
      text: "in testing",
      inTesting: true,
      color: colors.grey.base,
    },
    {
      text: "in progress",
      value: 0,
      color: colors.blue.base,
    },
    {
      text: "cancelled",
      value: -1,
      color: colors.red.base,
    },
  ],
  transferTypes: [
    { text: "Assembled Units", value: "assembled" },
    { text: "Product Components", value: "components" },
    { text: "Raw Materials", value: "rawMaterials" },
  ],
  transferStatus: [
    { text: "Cancelled", value: "cancelled", color: colors.red.base },
    { text: "In-Progress", value: "in progress", color: colors.blue.base },
    { text: "Complete", value: "complete", color: colors.green.base },
  ],
};

const actions = {
  _originalReconcileInventory({ commit }, payload) {
    commit("setOriginalReconcileInventory", payload);
  },
  _reconcileInventory({ commit }, payload) {
    commit("setReconcileInventory", payload);
  },
  _workOrderProducts({ commit }, payload) {
    commit("setWorkOrderProducts", payload);
  },
};

const mutations = {
  setOriginalReconcileInventory(state, payload) {
    state.originalInventory = payload;
  },
  setReconcileInventory(state, payload) {
    state.reconcile = payload;
  },
  setWorkOrderProducts(state, payload) {
    state.workOrderProducts = payload;
  },
};

const getters = {
  getWorkOrderStatus(state) {
    return state.status;
  },
  getOriginalReconcileInventory(state) {
    return state.originalInventory;
  },
  getReconcileInventory(state) {
    return state.reconcile;
  },
  getWorkOrderProducts(state) {
    return state.workOrderProducts;
  },
  getTransferTypes(state) {
    return state.transferTypes;
  },
  getTransferStatus(state) {
    return state.transferStatus;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
