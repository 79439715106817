<template>
  <v-form>
    <v-row>
      <v-col
        cols="12"
        md="12"
        sm="12"
        class="d-flex justify-center align-center"
      >
        <v-avatar
          v-if="!getRightDrawer.data.img"
          color="grey lighten-3"
          size="120"
          tile
          style="border: solid 1px #cccccc !important"
        >
          <span class="grey--text text--darken-2 text-subtitle-2 px-4"
            >No Image Uploaded</span
          >
        </v-avatar>
        <v-avatar v-else size="120" tile style="border: solid 1px #cccccc">
          <v-img
            contain
            :src="getRightDrawer.data.imgURL"
            :alt="`${$options.filters.capitalize(
              getRightDrawer.data.name
            )} Product Image`"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          label="Product Name"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.name | capitalize"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          label="SKU"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.sku.toUpperCase()"
          persistent-hint
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-textarea
          label="Product Description"
          outlined
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.desc"
          readonly
          :disabled="
            !getRightDrawer.data.desc || getRightDrawer.data.desc?.length === 0
          "
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          label="Product Color"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.color"
          readonly
        >
          <template v-slot:prepend-inner>
            <v-badge inline dot :color="getRightDrawer.data.color"></v-badge>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          label="Product Visibility"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            getProductVisibility.find(
              (option) => option.value === getRightDrawer.data.visibility
            ).text
          "
          :hint="
            getRightDrawer.data.visibility === null
              ? ''
              : descriptionForVisibilityStatus(
                  getRightDrawer.data.visibility,
                  'product'
                )
          "
          persistent-hint
          readonly
        >
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Strain Type"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            !getRightDrawer.data.strainType
              ? ''
              : getStrainTypes.find(
                  (option) => option.value === getRightDrawer.data.strainType
                ).text
          "
          readonly
          :disabled="!getRightDrawer.data.strainType"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Created"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            getRightDrawer.data.created.seconds | moment('MM/DD/YYYY @ hh:mm A')
          "
          :hint="`created by: ${getRightDrawer.data.createdBy}` | capitalize"
          persistent-hint
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Updated"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            getRightDrawer.data.updated.seconds | moment('MM/DD/YYYY @ hh:mm A')
          "
          persistent-hint
          :hint="`updated by: ${getRightDrawer.data.lastUpdated}` | capitalize"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "ProductPreview",
  data: () => ({}),
  methods: {
    ...mapActions(["_rightDrawer"]),
    /* API */
    // GET
    // POST
    /* Main */
    /* Misc */
    descriptionForVisibilityStatus(item, type) {
      switch (item) {
        case "public":
          return `This ${type} will be visible to sales reps and vendors.`;
        case "internal":
          return `This ${type} will be visible to sales reps and hidden from vendors.`;
        case "hidden":
          return `This ${type} will not be visible to sales reps or vendors.`;
      }
    },
  },
  computed: {
    ...mapGetters([
      "getAppName",
      "getUser",
      "getRightDrawer",
      "getProductVisibility",
      "getStrainTypes",
    ]),
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
  },
};
</script>
