<template>
  <v-form>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-expansion-panels flat multiple v-model="Array.from([0])">
          <v-expansion-panel>
            <v-expansion-panel-header class="px-3">
              <v-toolbar-title>Products</v-toolbar-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="response.headers.products"
                :items="getRightDrawer.data.products"
                no-data-text="No products"
                no-results-text="Product not found"
                loading-text="Loading Products..."
                disable-sort
                :items-per-page="-1"
                mobile-breakpoint="0"
                hide-default-footer
                dense
              >
                <template v-slot:item.number="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.name="{ item }">
                  <div class="py-1">
                    <span
                      class="text-capitalize font-weight-bold d-block"
                      style="line-height: 1rem !important"
                      >{{ item.name }}</span
                    >
                    <span
                      class="text-uppercase text-caption text-no-wrap d-block"
                      style="line-height: 1rem !important"
                      >{{ item.sku }}</span
                    >
                  </div>
                </template>
                <template v-slot:item.sku="{ item }">
                  <span class="text-uppercase">{{ item.sku }}</span>
                </template>
                <template v-slot:item.category="{ item }">
                  <span class="text-capitalize">{{ item.category }}</span>
                </template>
                <template v-slot:item.visibility="{ item }">
                  <span
                    v-if="item.visibility"
                    class="text-capitalize d-flex align-center"
                  >
                    <DelayedTooltip left z-index="99999999999">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="font-weight-bold text-caption rounded-pill d-flex align-center"
                          style="padding: 4px 8px; cursor: help"
                          v-bind="attrs"
                          v-on="on"
                          :style="`background-color: ${
                            getProductVisibility.find(
                              (visibility) =>
                                visibility.value === item.visibility
                            ).accentColor
                          }; color: ${
                            getProductVisibility.find(
                              (visibility) =>
                                visibility.value === item.visibility
                            ).color
                          };`"
                        >
                          <v-icon
                            style="font-size: 14px; margin: 0px 5px 0px 0px"
                            :style="`color: ${
                              getProductVisibility.find(
                                (visibility) =>
                                  visibility.value === item.visibility
                              ).color
                            }`"
                            >{{
                              getProductVisibility.find(
                                (visibility) =>
                                  visibility.value === item.visibility
                              ).icon
                            }}</v-icon
                          >
                          <span>
                            {{
                              getProductVisibility.find(
                                (visibility) =>
                                  visibility.value === item.visibility
                              ).text
                            }}</span
                          >
                        </span>
                      </template>
                      <span>{{
                        descriptionForVisibilityStatus(
                          item.visibility,
                          "product"
                        )
                      }}</span>
                    </DelayedTooltip>
                  </span>
                  <span v-else class="text--disabled">-</span>
                </template>
                <template v-slot:item.color="{ item }">
                  <v-badge dot inline :color="item.color"></v-badge>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-3">
              <v-toolbar-title>Available Locations</v-toolbar-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="response.headers.locations"
                loading-text="Loading Locations..."
                no-data-text="No Locations"
                no-results-text="Location not found"
                sort-by="name"
                :items-per-page="-1"
                hide-default-footer
                mobile-breakpoint="0"
                :items="getRightDrawer.data.locations"
                style="height: 100%"
                dense
              >
                <template v-slot:item.number="{ index }">
                  <span>{{ index + 1 }}</span>
                </template>
                <template v-slot:item.name="{ item }">
                  <span class="text-capitalize">{{ item.name }}</span>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          label="Name"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.name | capitalize"
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          label="Brand"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.brand?.text | capitalize"
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <template>
        <v-col cols="6">
          <v-text-field
            label="Wholesale Price"
            v-if="getRightDrawer.data.flags.includes('hasVariablePricing')"
            :color="$vuetify.theme.themes.dark.primary"
            value="Variable"
            hide-details
            readonly
          ></v-text-field>
          <v-text-field
            label="Wholesale Price"
            v-else
            :color="$vuetify.theme.themes.dark.primary"
            :value="getRightDrawer.data.price | currency"
            hide-details
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Visibility"
            :color="$vuetify.theme.themes.dark.primary"
            readonly
            :value="getRightDrawer.data.visibility?.text ?? '[Not Set]'"
            :prepend-inner-icon="getRightDrawer.data.visibility?.icon"
            hide-details
          >
            <template v-slot:append v-if="getRightDrawer.data.visibility">
              <DelayedTooltip z-index="99999999" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="$vuetify.theme.themes.dark.ledger"
                    style="font-size: 20px; cursor: help"
                    class="mr-1"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-information</v-icon
                  >
                </template>
                <span>{{
                  descriptionForVisibilityStatus(
                    getRightDrawer.data.visibility.value,
                    "category"
                  )
                }}</span>
              </DelayedTooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="For Sale?"
            :color="$vuetify.theme.themes.dark.primary"
            hide-details
            readonly
            :value="
              getRightDrawer.data.flags.includes('forSale')
                ? 'yes'
                : 'no' | capitalize
            "
            :class="
              getRightDrawer.data.flags.includes('forSale') ? 'text-green' : ''
            "
          >
            <template v-slot:append>
              <v-icon
                :color="
                  getRightDrawer.data.flags.includes('forSale')
                    ? $vuetify.theme.themes.dark.success
                    : $vuetify.theme.themes.dark.error
                "
                >{{
                  getRightDrawer.data.flags.includes("forSale")
                    ? $vuetify.icons.values.submit
                    : $vuetify.icons.values.cancel
                }}</v-icon
              >
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Visible in Store Events?"
            :color="$vuetify.theme.themes.dark.primary"
            hide-details
            readonly
            :value="
              getRightDrawer.data.flags.includes('storeEvents')
                ? 'yes'
                : 'no' | capitalize
            "
            :class="
              getRightDrawer.data.flags.includes('storeEvents')
                ? 'text-green'
                : ''
            "
          >
            <template v-slot:append>
              <v-icon
                :color="
                  getRightDrawer.data.flags.includes('storeEvents')
                    ? $vuetify.theme.themes.dark.success
                    : $vuetify.theme.themes.dark.error
                "
                >{{
                  getRightDrawer.data.flags.includes("storeEvents")
                    ? $vuetify.icons.values.submit
                    : $vuetify.icons.values.cancel
                }}</v-icon
              >
            </template>
          </v-text-field>
        </v-col>
        <v-col
          :cols="getRightDrawer.data.flags.includes('soldByCase') ? '6' : '12'"
        >
          <v-text-field
            label="Sold by Case?"
            :color="$vuetify.theme.themes.dark.primary"
            hide-details
            readonly
            :value="
              getRightDrawer.data.flags.includes('soldByCase')
                ? 'yes'
                : 'no' | capitalize
            "
            :class="
              getRightDrawer.data.flags.includes('soldByCase')
                ? 'text-green'
                : ''
            "
          >
            <template v-slot:append>
              <v-icon
                :color="
                  getRightDrawer.data.flags.includes('soldByCase')
                    ? $vuetify.theme.themes.dark.success
                    : $vuetify.theme.themes.dark.error
                "
                >{{
                  getRightDrawer.data.flags.includes("soldByCase")
                    ? $vuetify.icons.values.submit
                    : $vuetify.icons.values.cancel
                }}</v-icon
              >
            </template>
          </v-text-field>
        </v-col>
        <v-col v-if="getRightDrawer.data.flags.includes('soldByCase')" cols="6">
          <v-text-field
            label="Units/Case"
            :color="$vuetify.theme.themes.dark.primary"
            :value="getRightDrawer.data.caseQTY"
            hide-details
            readonly
          ></v-text-field>
        </v-col>
      </template>
      <template v-if="getRightDrawer.data.flags.includes('canManufacture')">
        <v-col cols="6">
          <v-text-field
            label="Contains Raw Materials?"
            :color="$vuetify.theme.themes.dark.primary"
            :value="
              getRightDrawer.data.rawMaterials ? 'yes' : 'no' | capitalize
            "
            :hint="`${
              getRightDrawer.data.rawMaterials
                ? getRightDrawer.data.rawMaterials.amt + 'g per unit'
                : ''
            }`"
            persistent-hint
            hide-details
            readonly
            :class="getRightDrawer.data.rawMaterials ? 'text-green' : ''"
          >
            <template v-slot:append>
              <v-icon
                :color="
                  getRightDrawer.data.rawMaterials
                    ? $vuetify.theme.themes.dark.success
                    : $vuetify.theme.themes.dark.error
                "
                >{{
                  getRightDrawer.data.rawMaterials
                    ? $vuetify.icons.values.submit
                    : $vuetify.icons.values.cancel
                }}</v-icon
              >
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Contains Terpenes?"
            :color="$vuetify.theme.themes.dark.primary"
            hide-details
            readonly
            :value="
              getRightDrawer.data.flags.includes('usesTerpenes')
                ? 'yes'
                : 'no' | capitalize
            "
            :class="
              getRightDrawer.data.flags.includes('usesTerpenes')
                ? 'text-green'
                : ''
            "
          >
            <template v-slot:append>
              <v-icon
                :color="
                  getRightDrawer.data.flags.includes('usesTerpenes')
                    ? $vuetify.theme.themes.dark.success
                    : $vuetify.theme.themes.dark.error
                "
                >{{
                  getRightDrawer.data.flags.includes("usesTerpenes")
                    ? $vuetify.icons.values.submit
                    : $vuetify.icons.values.cancel
                }}</v-icon
              >
            </template>
          </v-text-field>
        </v-col>
      </template>
    </v-row>
  </v-form>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "CategoryPreview",
  data: () => ({
    response: {
      headers: {
        products: [
          { text: "#", value: "number", sortable: false },
          { text: "Name", value: "name" },
          { text: "Color", value: "color", sortable: false },
          { text: "Visibility", value: "visibility", sortable: false },
        ],
        locations: [
          { text: "#", value: "number", sortable: false },
          { text: "Name", value: "name", sortable: true },
        ],
      },
    },
  }),
  methods: {
    ...mapActions(["_rightDrawer"]),
    /* API */
    // GET
    // POST
    /* Main */
    /* Misc */
    descriptionForVisibilityStatus(item, type) {
      switch (item) {
        case "public":
          return `This ${type} is visible to sales reps and vendors.`;
        case "internal":
          return `This ${type} is visible to sales reps and hidden from vendors.`;
        case "hidden":
          return `This ${type} is not be visible to sales reps or vendors.`;
      }
    },
  },
  computed: {
    ...mapGetters([
      "getAppName",
      "getUser",
      "getRightDrawer",
      "getProductVisibility",
    ]),
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
  },
};
</script>

<style>
.text-green input {
  color: rgb(0, 159, 0) !important;
  font-weight: 700 !important;
}
.text-red input {
  color: rgb(193, 61, 0) !important;
  font-weight: 700 !important;
}
.text-bold input {
  font-weight: 700 !important;
}
.pointerCursor input {
  cursor: pointer !important;
}
</style>
