const state = {
  alerts: [],
  fields: [
    {
      text: "Reminder",
      value: "reminder",
      desc: "Set a date/time to be reminded about this record",
      recordTypes: ["tasks", "salesOrders"],
      operators: [],
      trackUpdates: true,
      fieldOnRecord: false,
    },
    {
      text: "Status",
      value: "status",
      desc: "When this record is updated to [X] status",
      recordTypes: ["tasks", "salesOrders"],
      operators: ["=="],
      trackUpdates: true,
      fieldOnRecord: true,
    },
    {
      text: "Balance Due",
      value: "balanceDue",
      desc: "When this record's balance is $0.00",
      recordTypes: ["salesOrders"],
      operators: ["=="],
      trackUpdates: true,
      fieldOnRecord: true,
    },
    {
      text: "New Payment",
      value: "newPayment",
      desc: "When a new payment is added to this record",
      recordTypes: ["salesOrders"],
      operators: [],
      trackUpdates: true,
      fieldOnRecord: false,
    },
  ],
};

const actions = {
  _alerts({ commit }, payload) {
    commit("setAlerts", payload);
  },
};

const mutations = {
  setAlerts(state, payload) {
    state.alerts = payload;
  },
};

const getters = {
  getAlerts(state) {
    return state.alerts;
  },
  getAlertFieldTypes(state) {
    return state.fields;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
