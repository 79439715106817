export default {
  // Dev
  // apiKey: "AIzaSyBzurGwSbAQdFRFiJaEOojbS0yUPg2WErM",
  // authDomain: "dime-erp.firebaseapp.com",
  // projectId: "dime-erp",
  // storageBucket: "dime-erp.appspot.com",
  // messagingSenderId: "277307008225",
  // appId: "1:277307008225:web:98eb098bc12c70df4503a0",
  // Production
  apiKey: "AIzaSyCtO3bN_COrXB_rVIhpGP1jR5WAOS-jG5k",
  authDomain: "mytrace.dimeindustries.com",
  databaseURL: "https://dime-unalink.firebaseio.com",
  projectId: "dime-unalink",
  storageBucket: "dime-unalink.appspot.com",
  messagingSenderId: "895217082750",
  appId: "1:895217082750:web:d417cacb5393accfdd932f",
};
