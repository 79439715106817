import colors from "vuetify/lib/util/colors";

const state = {
  status: [
    {
      text: "New",
      value: "new",
      color: colors.grey.base,
      desc: "New ticket has been assigned to a support rep",
      sort: 0,
      complete: false,
      inProgress: false,
    },
    {
      text: "In Progress",
      value: "in progress",
      color: colors.blue.base,
      desc: "Customer support has initiated contact with customer to confirm if they still have their product",
      sort: 1,
      complete: false,
      inProgress: true,
    },
    {
      text: "Waiting for Customer",
      value: "waiting for customer",
      desc: "Customer has not responded yet. May be missing documentation or needs to contact store about exchange",
      color: colors.orange.base,
      sort: 2,
      complete: false,
      inProgress: true,
    },
    {
      text: "Waiting for Store",
      value: "waiting for store",
      desc: "Store has not responded yet. Store may need to confirm inventory to exchange is available",
      color: colors.deepOrange.base,
      sort: 3,
      complete: false,
      inProgress: true,
    },
    {
      text: "Complete",
      value: "closed",
      color: colors.green.base,
      desc: "Ticket is complete",
      sort: 4,
      complete: true,
      inProgress: false,
    },
  ],
  types: [
    {
      text: "Exchange",
      subtitle: "Select original/replacement products",
      value: "exchange",
      subcategory: true,
    },
    // {
    //   text: "Business",
    //   subtitle: "Brand Related",
    //   value: "business",
    //   subcategory: false,
    // },
    // {
    //   text: "Internal",
    //   subtitle: "Inside the company (Technical issues/HR)",
    //   value: "internal",
    //   subcategory: false,
    // },
    // {
    //   text: "Other",
    //   value: "other",
    //   subcategory: false,
    // },
  ],
  source: [
    {
      text: "Online/Website",
      value: "online",
      desc: "Online form submission on brand's website",
    },
    {
      text: "Email",
      value: "email",
      desc: "Started through email / forwarded from an email",
    },
    {
      text: "Account",
      value: "account",
      desc: "Originated by account/store reaching out to brand's contact",
    },
    {
      text: "In Person",
      value: "inPerson",
      desc: "Customer speaking directly with brand's sales rep",
    },
  ],
  subcategories: [
    {
      text: "Account",
      value: "exchange_account",
      type: "exchange",
      desc: "Store will handle this exchange",
    },
    // {
    //   text: "No Device",
    //   value: "exchange_noDevice",
    //   type: "exchange",
    //   desc: "Customer has disposed of product",
    // },
    {
      text: "Brand",
      value: "exchange_brand",
      type: "exchange",
      desc: "The brand will fully handle this exchange",
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getTicketStatus(state) {
    return state.status;
  },
  getTicketTypes(state) {
    return state.types;
  },
  getTicketSources(state) {
    return state.source;
  },
  getTicketSubcategories(state) {
    return state.subcategories;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
