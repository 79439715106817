import { colors } from "vuetify/lib";

const state = {
  status: [
    {
      text: "Cancelled",
      value: "cancelled",
      sort: 1,
      color: colors.red.base,
    },
    {
      text: "In Progress",
      value: "in progress",
      sort: 2,
      color: colors.yellow.darken2,
    },
    {
      text: "Complete",
      value: "complete",
      sort: 3,
      color: colors.green.base,
    },
  ],
  getExpenseRelations: [
    {
      text: "Expense Vendors",
      value: "expenseVendor",
    },
    {
      text: "Accounts",
      value: "account",
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getExpenseStatus(state) {
    return state.status;
  },
  getExpenseRelations(state) {
    return state.getExpenseRelations;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
