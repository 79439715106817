<template>
  <v-container fluid class="pa-0 rounded-lg">
    <v-card flat width="auto" class="rounded-lg">
      <v-card-text class="pa-0">
        <v-form>
          <v-row>
            <v-col>
              <v-menu
                offset-y
                :close-on-content-click="false"
                ref="menu"
                transition="scale-transition"
                min-width="auto"
                v-model="payload.filters.dateRange.menu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Date Range"
                    :color="$vuetify.theme.themes.dark.primary"
                    v-on="on"
                    v-bind="attrs"
                    :value="dateRange"
                    hint="This is listening in real-time & does not need to be reloaded for updates"
                    persistent-hint
                    readonly
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        small
                        rounded
                        :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
                        @click="traverse('load')"
                      >
                        <span class="mr-1">load</span>
                        <v-icon>{{ $vuetify.icons.values.query }}</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  range
                  :color="$vuetify.theme.themes.dark.primary"
                  :max="today"
                  v-model="payload.filters.dateRange.values"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
        <v-list class="py-0">
          <v-list-item v-if="response.phoneLog.length === 0">
            <v-list-item-content>
              <v-list-item-title class="text-center">
                <span class="text--disabled">No calls.</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item-group v-else>
            <template v-for="(call, index) in response.phoneLog">
              <v-list-item three-line :key="call.id">
                <v-hover v-slot="{ hover }">
                  <v-fade-transition leave-absolute>
                    <v-list-item-content>
                      <div class="d-flex justify-space-between">
                        <div>
                          <span>{{ call.phoneNumber | phoneNumber }}</span>
                          <v-btn
                            v-if="hover || $vuetify.breakpoint.mdAndDown"
                            plain
                            x-small
                            :color="$vuetify.theme.themes.dark.review"
                            :loading="loading.call"
                            @click="traverse('call', call)"
                          >
                            <span
                              :style="`color: ${$vuetify.theme.themes.dark.review}`"
                              class="mr-1 font-weight-bold text-caption text-capitalize"
                              >call</span
                            >
                            <v-icon size="12">{{
                              $vuetify.icons.values.phoneNumber
                            }}</v-icon>
                          </v-btn>
                        </div>
                        <div>
                          <v-icon size="12">mdi-clock</v-icon>
                          <span
                            class="ml-1 text--secondary font-weight-bold text-caption text-capitalize"
                            >{{
                              call.created | moment("M/D/YY @ h:mm A")
                            }}</span
                          >
                        </div>
                      </div>
                      <v-list-item-title>
                        <span
                          class="text-wrap font-weight-medium text-capitalize"
                          >{{
                            call.contact
                              ? `${
                                  call.contact.name.first
                                } ${call.contact.name.last.charAt(0)}.`
                              : "--CONTACT NOT FOUND--"
                          }}
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <div
                          style="gap: 8px"
                          class="d-flex align-center justify-space-between"
                        >
                          <span
                            class="font-weight-bold text-wrap text-capitalize"
                            style="min-height: 20px !important"
                          >
                            {{
                              call.relatedTo ? call.relatedTo.name : "-"
                            }}</span
                          >
                          <div>
                            <v-btn
                              v-if="
                                (hover || $vuetify.breakpoint.mdAndDown) &&
                                call.relatedTo
                              "
                              plain
                              x-small
                              :color="$vuetify.theme.themes.dark.review"
                              @click.stop="traverse('related-view', call)"
                              :disabled="loading.call"
                            >
                              <span
                                :style="`color: ${$vuetify.theme.themes.dark.review}`"
                                class="mr-1 font-weight-bold text-caption text-capitalize"
                                >account</span
                              >
                              <v-icon size="12">{{
                                $vuetify.icons.values.view
                              }}</v-icon>
                            </v-btn>
                            <v-btn
                              v-if="
                                (hover || $vuetify.breakpoint.mdAndDown) &&
                                call.contact
                              "
                              plain
                              x-small
                              :color="$vuetify.theme.themes.dark.review"
                              @click.stop="traverse('contact-view', call)"
                              :disabled="loading.call"
                            >
                              <span
                                :style="`color: ${$vuetify.theme.themes.dark.review}`"
                                class="mr-1 font-weight-bold text-caption text-capitalize"
                                >contact</span
                              >
                              <v-icon size="12">{{
                                $vuetify.icons.values.view
                              }}</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <div
                          class="d-flex justify-space-between align-center"
                        ></div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-fade-transition>
                </v-hover>
              </v-list-item>
              <v-divider :key="`${index}-divider`" />
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import {
  // Auth
  auth,
  // Firestore
  firestore,
  collection,
  onSnapshot,
  query,
  where,
  // Functions
  functions,
  httpsCallable,
  // Remote Config
  remoteConfig,
  getValue,
} from "@/firebase/init";
import moment from "moment";
// Components

export default {
  name: "Notifications",
  data: () => ({
    loading: {
      call: false,
    },
    response: {
      phoneLog: [],
    },
    payload: {
      filters: {
        dateRange: {
          menu: false,
          values: [
            moment().subtract(1, "day").startOf("day").format("YYYY-MM-DD"),
            moment().endOf("day").format("YYYY-MM-DD"),
          ],
        },
      },
    },
  }),
  async created() {
    this.setup();
  },
  methods: {
    ...mapActions(["_rightDrawer"]),
    /* API */
    // GET
    async getCallHistory() {
      this.response.phoneLog = [];
      const callsRef = collection(firestore, "phoneLog");
      const relatedUser = where("assignedTo.uid", "==", this.getUser.uid),
        relatedPhoneNumber = where(
          "assignedTo.phoneNumber",
          "==",
          this.getUser.phoneNumber
        );
      const dates = this.payload.filters.dateRange.values.sort((a, b) =>
        a > b ? 1 : -1
      );

      const start = where(
          "created",
          ">=",
          moment(dates[0]).startOf("day").toDate()
        ),
        end = where("created", "<=", moment(dates[1]).endOf("day").toDate());

      const q = query(callsRef, relatedUser, relatedPhoneNumber, start, end);
      onSnapshot(q, (response) => {
        if (!response.empty) {
          this.response.phoneLog = response.docs
            .map((call) => {
              return {
                id: call.id,
                created: moment(call.data().created.seconds * 1000).toDate(),
                phoneNumber: call.data().phoneNumber,
                contact: call.data().contact,
                relatedTo: call.data().relatedTo,
                icon:
                  call.data().direction === "inbound"
                    ? "mdi-phone-incoming"
                    : "mdi-phone-outgoing",
              };
            })
            .sort((a, b) => (a.created > b.created ? -1 : 1));
        } else {
          this.response.phoneLog = [];
        }
      });
    },
    // POST
    async startCall(call) {
      this.loading.call = true;
      const outboundCall = httpsCallable(functions, "outboundCall");
      const countryCode = "1";
      await outboundCall({
        email: this.getUser.email,
        userPhoneNumber: this.getUser.phoneNumber,
        contactPhoneNumber: `+${countryCode}${call.phoneNumber}`,
      });
      this.loading.call = false;
    },
    /* Main */
    async setup() {
      if (auth.currentUser) {
        await this.getCallHistory();
      }
    },
    async traverse(action, params) {
      const dialpadActive = getValue(remoteConfig, "dialpad").asBoolean();

      switch (action) {
        case "load":
          this.getCallHistory();
          break;
        case "call":
          if (dialpadActive) {
            this.startCall(params);
          } else {
            window.open(`tel://+1${params.phoneNumber}`, "_self");
          }
          break;
        case "contact-view":
          this.$router.push({
            name: `contacts-view`,
            params: {
              id: params.contact.id,
            },
          });
          break;
        case "related-view":
          this.$router.push({
            name: `${params.relatedTo.resource}-view`,
            params: {
              id: params.relatedTo.id,
            },
          });
          break;
      }
    },
    /* Misc */
  },
  computed: {
    ...mapGetters([
      // App
      "getAppName",
      "getUser",
      "getRightDrawer",
    ]),
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
    today() {
      return moment().format("YYYY-MM-DD");
    },
    dateRange() {
      return this.payload.filters.dateRange.values
        .map((date) => moment(date).format("MM/DD/YYYY"))
        .join(" - ");
    },
  },
};
</script>

<style></style>
