import colors from "vuetify/lib/util/colors";

const state = {
  couriers: [
    {
      text: "DHL",
      value: "https://www.dhl.com/en/express/tracking.html?AWB=",
      url: "https://www.dhl.com/en/express/tracking.html?AWB=",
      code: "dhl_express",
    },
    {
      text: "FedEX",
      value:
        "https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=",
      url: "https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=",
      code: "fedex",
    },
    {
      text: "UPS",
      value: "https://www.ups.com/track?loc=en_US&tracknum=",
      url: "https://www.ups.com/track?loc=en_US&tracknum=",
      code: "ups",
    },
    { text: "Other", value: "", url: "", code: "" },
  ],
  status: [
    { text: "Cancelled", value: "cancelled", color: colors.red.base },
    {
      text: "Awaiting Approval",
      value: "awaiting approval",
      color: colors.grey.base,
    },
    { text: "In Progress", value: "in progress", color: colors.blue.base },
    { text: "Completed", value: "complete", color: colors.green.base },
  ],
  orderTypes: [
    {
      text: "Vendor",
      value: "vendor",
    },
    {
      text: "Custom",
      value: "custom",
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getCouriers(state) {
    return state.couriers;
  },
  getVendorOrderStatus() {
    return state.status;
  },
  getVendorOrderTypes() {
    return state.orderTypes;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
