<template>
  <v-form>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-3">
              <v-toolbar-title>Line Items</v-toolbar-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="response.headers.rawMaterialOrderLineItems"
                :items="getRightDrawer.data.previewLineItems"
                multi-sort
                :sort-by="['rawMaterial', 'subCategory']"
                hide-default-footer
                mobile-breakpoint="0"
                :items-per-page="-1"
              >
                <template v-slot:item.number="{ index }">
                  <span>{{ index + 1 }}</span>
                </template>
                <template v-slot:item.availableAtLocation="{ item }">
                  <template v-if="item.availableAtLocation">
                    <v-icon :color="$vuetify.theme.themes.dark.success">{{
                      $vuetify.icons.values.submit
                    }}</v-icon>
                  </template>
                  <template v-else>
                    <span class="text--disabled">-</span>
                  </template>
                </template>
                <template v-slot:item.rawMaterial="{ item }">
                  <span class="text-capitalize">{{
                    item.rawMaterial
                  }}</span>
                </template>
                <template v-slot:item.subCategory="{ item }">
                  <span class="text-capitalize">{{
                    item.subCategory
                  }}</span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span>{{ item.amount | addComma }}g</span>
                </template>
                <template v-slot:item.batchName="{ item }">
                  <span class="text-uppercase">{{ item.batchName }}</span>
                </template>
                <template v-slot:item.notes="{ item }">
                  <template v-if="item.notes.length > 0">
                    <span>{{ item.notes }}</span>
                  </template>
                  <template v-else>
                    <span class="text--disabled">-</span>
                  </template>
                </template>
                <template v-slot:item.price="{ item }">
                  <span>{{ item.price | currency }}</span>
                </template>
                <template v-slot:item.total="{ item }">
                  <span>{{ (item.amount * item.price) | currency }}</span>
                </template>
                <template v-slot:item.split="{ item }">
                  <DelayedTooltip
                    z-index="99999999"
                    left
                    v-if="item.batches?.length > 0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        :color="$vuetify.theme.themes.dark.review"
                        style="font-size: 20px"
                        class="mr-1"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-information</v-icon
                      >
                    </template>
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <span class="mb-2 d-block"
                            ><strong>Split Batch</strong></span
                          >
                          <v-row
                            dense
                            class="d-block"
                            v-for="(batch, index) in item.batches"
                            :key="index"
                          >
                            <v-col cols="12" class="my-0 py-0">
                              <span
                                >{{ batch.batchName }}:
                                <span>{{ batch.amount }}g</span></span
                              >
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </DelayedTooltip>
                  <template v-else>
                    <span class="text--disabled">-</span>
                  </template>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Brand"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.brand.name | capitalize"
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Raw Material Vendor"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.vendor.name | capitalize"
          hide-details
          readonly
        >
          <template v-slot:append-outer>
            <v-btn
              small
              rounded
              :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
              @click="traverse('rawMaterialVendor-view')"
            >
              <span class="mr-1">view</span>
              <v-icon>{{ $vuetify.icons.values.view }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Purchase Date"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.purchased | moment('MM/DD/YYYY')"
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Fulfillment Location"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.fulfillmentLocation | capitalize"
          persistent-hint
          :hint="
            `${
              getRightDrawer.data.location.state.name
            } (${getRightDrawer.data.location.state.abbreviation.toUpperCase()}), ${
              getRightDrawer.data.location.country.name
            }` | capitalize
          "
          readonly
        >
          <template v-slot:append-outer>
            <v-btn
              small
              rounded
              :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
              @click="traverse('fulfillmentLocation-view')"
            >
              <span class="mr-1">view</span>
              <v-icon>{{ $vuetify.icons.values.view }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Created"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            (getRightDrawer.data.created.seconds * 1000)
              | moment('MM/DD/YYYY @ hh:mm A')
          "
          persistent-hint
          :hint="
            `created by: ${getRightDrawer.data.createdBy.name.first} ${getRightDrawer.data.createdBy.name.last}`
              | capitalize
          "
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Updated"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            (getRightDrawer.data.updated.seconds * 1000)
              | moment('MM/DD/YYYY @ hh:mm A')
          "
          persistent-hint
          :hint="
            `last updated: ${getRightDrawer.data.lastUpdated.name.first} ${getRightDrawer.data.lastUpdated.name.last}`
              | capitalize
          "
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "RawMaterialOrderPreview",
  data: () => ({
    response: {
      headers: {
        rawMaterialOrderLineItems: [
          {
            text: "#",
            value: "number",
            sortable: false,
          },

          {
            text: "Raw Material",
            value: "rawMaterial",
            sortable: true,
          },
          {
            text: "Sub-Category",
            value: "subCategory",
            sortable: true,
          },
          {
            text: "Batch Name",
            value: "batchName",
            sortable: true,
          },
          {
            text: "Notes",
            value: "notes",
            sortable: false,
          },
          {
            text: "Amount purchased",
            value: "amount",
            sortable: true,
          },
          {
            text: "Price",
            value: "price",
            sortable: true,
          },
          {
            text: "Total",
            value: "total",
            sortable: true,
          },
          {
            text: "Split",
            value: "split",
            sortable: false,
          },
          {
            text: "Available at location?",
            value: "availableAtLocation",
            sortable: true,
          },
        ],
      },
    },
  }),
  methods: {
    ...mapActions(["_rightDrawer"]),
    /* API */
    // GET
    // POST
    /* Main */
    async traverse(action, params) {
      let link;
      switch (action) {
        case "fulfillmentLocation-view":
          link = this.$router.resolve({
            name: "location-rawMaterials-overview",
            params: {
              locationID:
                this.getRightDrawer.data.location.fulfillmentLocation.id,
            },
          });
          window.open(link.href, "_blank");
          break;
        case "rawMaterialVendor-view":
          this.$router.push({
            name: "rawMaterialVendors-view",
            params: {
              id: this.getRightDrawer.data.vendor.id,
            },
          });
          break;
      }
    },
    /* Misc */
  },
  computed: {
    ...mapGetters([
      "getAppName",
      "getUser",
      "getRightDrawer",
      "getProductVisibility",
      "getStrainTypes",
    ]),
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
  },
};
</script>
