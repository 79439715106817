<template>
  <v-card>
    <v-card-title
      class="justify-center"
      :style="`background: ${$vuetify.theme.themes.dark.primary}; color: ${$vuetify.theme.themes.dark.secondary};`"
    >
      <span class="text-uppercase">action list item | review</span>
    </v-card-title>
    <v-card-text class="my-3">
      <!-- Current View -->
      <v-alert :color="$vuetify.theme.themes.dark.ledger" dark>
        <v-icon large class="mr-2">{{
          $vuetify.icons.values.actionList
        }}</v-icon>
        <span class="text-capitalize">action list item - view</span>
      </v-alert>
      <v-row class="mb-2">
        <!-- Account -->
        <v-col cols="12" md="7" sm="12">
          <template
            v-if="
              response.actionList && response.account && response.brandMetrics
            "
          >
            <v-card flat outlined class="rounded-lg">
              <v-card-title
                :style="`background: ${$vuetify.theme.themes.dark.primary}; color: ${$vuetify.theme.themes.dark.secondary};`"
              >
                <v-icon
                  large
                  class="mr-2"
                  :color="$vuetify.theme.themes.dark.secondary"
                  >{{ $vuetify.icons.values.account }}</v-icon
                >
                <span class="text-uppercase">account</span>
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      <v-text-field
                        label="Name"
                        :color="$vuetify.theme.themes.dark.primary"
                        :value="response.account.name | capitalize"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <v-text-field
                        label="Phone #"
                        :color="$vuetify.theme.themes.dark.primary"
                        :value="response.account.phoneNumber | phoneNumber"
                        readonly
                        :disabled="response.account.phoneNumber.length === 0"
                      >
                        <template v-slot:append-outer>
                          <v-btn
                            v-if="response.account.phoneNumber.length > 0"
                            small
                            rounded
                            :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
                            :loading="loading.call"
                            @click="
                              traverse('call', response.account.phoneNumber)
                            "
                          >
                            <v-icon>{{
                              $vuetify.icons.values.phoneNumber
                            }}</v-icon>
                            <span>call</span>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
                <v-data-table
                  :headers="response.headers.contacts"
                  :loading="loading.contacts"
                  loading-text="Loading contacts..."
                  :items="response.contacts"
                  sort-by="name"
                  no-data-text="No Contacts"
                >
                  <template v-slot:item.number="{ index }">
                    <span>{{ index + 1 }}</span>
                  </template>
                  <template v-slot:item.name="{ item }">
                    <span class="text-capitalize">{{ item.name }}</span>
                  </template>
                  <template v-slot:item.phoneNumber="{ item }">
                    <template v-if="item.phoneNumber.length > 0">
                      <v-btn
                        small
                        rounded
                        :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
                        :loading="loading.call"
                        @click="traverse('call', item.phoneNumber)"
                      >
                        <v-icon>{{ $vuetify.icons.values.phoneNumber }}</v-icon>
                        <span>call</span>
                      </v-btn>
                    </template>
                    <template v-else>
                      <span
                        class="text-uppercase font-weight-bold"
                        :style="`color: ${$vuetify.theme.themes.dark.error};`"
                        >No phone # set</span
                      >
                    </template>
                  </template>
                  <template v-slot:item.notes="{ item }">
                    <template v-if="item.notes.length > 0">
                      <span>{{ item.notes }}</span>
                    </template>
                    <template v-else>
                      <span class="text--disabled">-</span>
                    </template>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </template>
          <template v-else>
            <v-skeleton-loader type="card" />
          </template>
        </v-col>
        <!-- Action List -->
        <v-col cols="12" md="5" sm="12">
          <template v-if="response.actionList">
            <v-card flat outlined class="rounded-lg">
              <v-card-title
                :style="`background: ${$vuetify.theme.themes.dark.primary}; color: ${$vuetify.theme.themes.dark.secondary};`"
              >
                <v-icon
                  large
                  class="mr-2"
                  :color="$vuetify.theme.themes.dark.secondary"
                  >{{ $vuetify.icons.values.actionList }}</v-icon
                >
                <span class="text-uppercase">action list</span>
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col cols="12" md="6" sm="3">
                      <v-text-field
                        label="Assigned To"
                        :color="$vuetify.theme.themes.dark.primary"
                        :value="response.actionList.assignedTo | capitalize"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="3">
                      <v-text-field
                        label="Type"
                        :color="$vuetify.theme.themes.dark.primary"
                        :value="response.actionList.type.text"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="3">
                      <v-text-field
                        label="Created"
                        :color="$vuetify.theme.themes.dark.primary"
                        :value="
                          response.actionList.created | moment('MM/DD/YYYY')
                        "
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="3">
                      <v-text-field
                        label="Due Date"
                        :color="$vuetify.theme.themes.dark.primary"
                        :value="
                          response.actionList.dueDate | moment('MM/DD/YYYY')
                        "
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </template>
          <template v-else>
            <v-skeleton-loader type="card" />
          </template>
        </v-col>
      </v-row>
      <!-- Disposition -->
      <v-card flat outlined class="rounded-lg mb-3">
        <v-card-title
          :style="`background: ${
            invalidAccountUpdate
              ? $vuetify.theme.themes.dark.review
              : $vuetify.theme.themes.dark.primary
          }; color: ${$vuetify.theme.themes.dark.secondary};`"
        >
          <v-icon
            large
            class="mr-2"
            :color="$vuetify.theme.themes.dark.secondary"
            >{{ $vuetify.icons.values.review }}</v-icon
          >
          <span class="text-uppercase">result</span>
        </v-card-title>
        <v-card-text>
          <!-- Conditions -->
          <v-card v-if="actionListType" flat>
            <v-card-title>
              <v-icon large class="mr-2">{{
                $vuetify.icons.values.warning
              }}</v-icon>
              <span class="text-uppercase">conditions</span>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="response.headers.conditions"
                :items="conditions"
                :items-per-page="-1"
                no-data-text="No conditions set"
                hide-default-footer
                disable-sort
              >
                <template v-slot:item.number="{ index }">
                  <span>{{ index + 1 }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <!-- Disposition -->
          <v-form>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-select
                  label="Disposition"
                  :background-color="dispositionSet ? '' : 'yellow lighten-3'"
                  :color="$vuetify.theme.themes.dark.primary"
                  :item-color="$vuetify.theme.themes.dark.primary"
                  :items="dispositions"
                  no-data-text="No Dispositions"
                  v-model="payload.updates.disposition"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>{{ item.text }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span>{{ item.desc }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
              <!-- Follow Up Task -->
              <template v-if="selectedDisposition?.task">
                <v-col cols="12" md="3" sm="12">
                  <v-select
                    label="Task Type"
                    :color="$vuetify.theme.themes.dark.primary"
                    :item-color="$vuetify.theme.themes.dark.primary"
                    :items="getTaskTypes"
                    v-model="payload.task.type"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                  <v-menu
                    offset-y
                    :close-on-content-click="false"
                    ref="menu"
                    transition="scale-transition"
                    min-width="auto"
                    v-model="payload.task.dateMenu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Follow up date"
                        :background-color="
                          followUpDateSet ? '' : 'yellow lighten-3'
                        "
                        :color="$vuetify.theme.themes.dark.primary"
                        v-on="on"
                        v-bind="attrs"
                        :value="formattedDate"
                        readonly
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      :color="$vuetify.theme.themes.dark.primary"
                      :min="today"
                      v-model="payload.task.dueDate"
                    >
                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-autocomplete
                    label="Related Contact"
                    :color="$vuetify.theme.themes.dark.shipping"
                    :item-color="$vuetify.theme.themes.dark.shipping"
                    :items="response.contacts"
                    :hint="`${response.contacts.length} contact${
                      response.contacts.length === 0 ||
                      response.contacts.length > 1
                        ? 's'
                        : ''
                    } available`"
                    persistent-hint
                    no-data-text="No contacts"
                    v-model="payload.task.contact"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="text-capitalize">{{ item.name }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span>{{ item.title }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span class="text-capitalize">{{ item.name }}</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-textarea
                    label="Notes"
                    outlined
                    :background-color="taskNotesSet ? '' : 'yellow lighten-3'"
                    :color="$vuetify.theme.themes.dark.primary"
                    v-model.trim="payload.task.notes"
                  ></v-textarea>
                </v-col>
              </template>
              <!-- Samples/Order -->
              <template
                v-if="
                  payload.updates.disposition === 'samples_yes' ||
                  payload.updates.disposition === 'firstOrder'
                "
              >
                <v-col cols="12">
                  <v-btn
                    rounded
                    block
                    :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
                    @click="traverse('salesOrder-new')"
                  >
                    <span class="mr-1">create order</span>
                    <v-icon small>{{ $vuetify.icons.values.newTab }}</v-icon>
                  </v-btn>
                </v-col>
              </template>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-btn
        small
        rounded
        block
        :style="`background: ${$vuetify.theme.themes.dark.update}; color: ${$vuetify.theme.themes.dark.secondary};`"
        :loading="loading.submit"
        @click="traverse('submit')"
        :disabled="invalidAccountUpdate"
      >
        <span>submit</span>
        <v-icon>{{ $vuetify.icons.values.submit }}</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import {
  // Firestore
  firestore,
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  serverTimestamp,
  increment,
  writeBatch,
  // Functions
  functions,
  httpsCallable,
  // Remote Config
  remoteConfig,
  getValue,
} from "@/firebase/init";
import moment from "moment";
// Components

export default {
  name: "action-list-item-view",
  data: () => ({
    loading: {
      overall: false,
      account: false,
      contacts: false,
      actionList: false,
      call: false,
      submit: false,
    },
    response: {
      headers: {
        contacts: [
          {
            text: "#",
            value: "number",
            sortable: false,
          },
          {
            text: "Name",
            value: "name",
            sortable: true,
          },
          {
            text: "Title",
            value: "title",
            sortable: false,
          },
          {
            text: "Contact Preference",
            value: "contactPreference",
            sortable: false,
          },
          {
            text: "Phone #",
            value: "phoneNumber",
            sortable: false,
          },
          {
            text: "Notes",
            value: "notes",
            sortable: false,
          },
        ],
        conditions: [
          {
            text: "#",
            value: "number",
          },
          {
            text: "Field",
            value: "field",
          },
          {
            text: "Current account value",
            value: "account",
          },
        ],
      },
      actionList: null,
      account: null,
      brandMetrics: null,
      contacts: [],
    },
    payload: {
      updates: {
        disposition: null,
      },
      task: {
        notes: "",
        contact: null,
        type: "call",
        taskMenu: false,
        dueDate: null,
      },
      clicked: false,
    },
  }),
  mounted() {
    this.reset();
  },
  created() {
    this.setup();
  },
  methods: {
    ...mapActions(["_actionList"]),
    /* API Calls */
    // GET
    async getAccount() {
      this.response.account = null;

      const accountDoc = doc(
        firestore,
        "accounts",
        this.getActionListRecord.accountID
      );
      const response = await getDoc(accountDoc);

      if (response.exists()) {
        this.response.account = {
          id: response.id,
          ...response.data(),
        };
      }
      this.loading.account = false;
    },
    async getContacts() {
      this.loading.contacts = true;
      this.response.contacts = [];

      const contactsRef = collection(firestore, "contacts");
      const active = where("active", "==", true),
        staff = where("title", "!=", "customer"),
        related = where("related.id", "==", this.getActionListRecord.accountID);
      const q = query(contactsRef, active, staff, related);

      const response = await getDocs(q);
      if (!response.empty) {
        this.response.contacts = response.docs
          .map((contact) => {
            return {
              name: `${contact.data().name.first} ${contact.data().name.last}`,
              title: this.getContactTitles.find(
                (option) => option.value === contact.data().title
              ).text,
              contactPreference:
                contact.data().contactPreference?.length > 0
                  ? this.getContactPreferences.find(
                      (option) =>
                        option.value === contact.data().contactPreference
                    ).text
                  : "-",
              phoneNumber: contact.data().phoneNumber,
              notes: contact.data().notes,
              value: contact.id,
              id: contact.id,
            };
          })
          .sort((a, b) => (a.name > b.name ? 1 : -1));
      }

      this.loading.contacts = false;
    },
    async getBrandMetrics() {
      this.response.brandMetrics = null;

      const brandStatsDoc = doc(
        firestore,
        "brandStats",
        `${this.getActionListRecord.accountID}_${this.getActionListRecord.actionList.brandID}`
      );
      const response = await getDoc(brandStatsDoc);

      if (response.exists()) {
        this.response.brandMetrics = {
          id: response.id,
          account: response.data().account,
          brand: response.data().brand,
          location: response.data().location,
          balance: this.$options.filters.currency(
            response.data().metrics.balance
          ),
          chargeOff: this.$options.filters.currency(
            response.data().metrics.chargeOffBalance
          ),
          viewed: response.data()?.viewed
            ? moment(response.data()?.viewed.seconds * 1000).format(
                "MM/DD/YYYY"
              )
            : "not viewed".toUpperCase(),
          lastTask: response.data().metrics.last.task
            ? moment(response.data().metrics.last.task.seconds * 1000).format(
                "MM/DD/YYYY"
              )
            : "no tasks".toUpperCase(),
          lastOrder: response.data().metrics.last.order
            ? moment(response.data().metrics.last.order.seconds * 1000).format(
                "MM/DD/YYYY"
              )
            : "no orders".toUpperCase(),
          lastStoreEvent: response.data().metrics.last.storeEvent
            ? moment(
                response.data().metrics.last.storeEvent.seconds * 1000
              ).format("MM/DD/YYYY")
            : "no store events".toUpperCase(),
          lastTraining: response.data().metrics.last.budtenderTraining
            ? moment(
                response.data().metrics.last.budtenderTraining.seconds * 1000
              ).format("MM/DD/YYYY")
            : "no trainings".toUpperCase(),
        };
      }
      this.loading.brandMetrics = false;
    },
    async getActionList() {
      this.loading.actionList = true;
      this.response.actionList = null;

      const actionListDoc = doc(
        firestore,
        "actionLists",
        this.getActionListRecord.actionList.id
      );
      const response = await getDoc(actionListDoc);

      if (response.exists()) {
        this.response.actionList = {
          id: response.id,
          assignedTo: `${response.data().assignedTo.name}`,
          created: moment(response.data().created.seconds * 1000).toDate(),
          dueDate: moment(response.data().dueDate.seconds * 1000).toDate(),
          type: this.getAccountStatusGroups.find(
            (option) => option.value === response.data().type
          ),
          status: this.getActionListStatus.find(
            (option) => option.value === response.data().status
          ),
          accounts: response.data().accounts,
        };
      }
      this.loading.actionList = false;
    },
    // POST
    async submit() {
      this.loading.submit = true;
      const batch = writeBatch(firestore);

      const actionListDoc = doc(
          firestore,
          "actionLists",
          this.response.actionList.id
        ),
        actionListItem = doc(
          firestore,
          "actionLists_tasks",
          this.getActionListRecord.actionListItemID
        );

      const disposition = this.getActionListDispositions.find(
        (option) => option.value === this.payload.updates.disposition
      );
      // Action Item
      batch.set(
        actionListItem,
        {
          updated: serverTimestamp(),
          completedDate: serverTimestamp(),
          completedBy: {
            name: this.getUser.name,
            uid: this.getUser.uid,
          },
          flags: {
            complete: true,
          },
          disposition: this.payload.updates.disposition,
          sentiment: disposition.sentiment,
        },
        { merge: true }
      );
      // Create task
      if (disposition.task) {
        let contact = null,
          status = this.getTaskStatus.find(
            (option) => option.value === "notStarted"
          );
        if (this.payload.task.contact) {
          const selectedContact = this.response.contacts.find(
            (contact) => contact.id === this.payload.task.contact
          );

          contact = {
            name: selectedContact.name,
            id: selectedContact.id,
          };
        }

        let users = [this.getUser.uid];
        if (this.getUser.reportsTo) {
          users.push(this.getUser.reportsTo);
        }

        const taskDoc = doc(collection(firestore, "tasks"));
        batch.set(
          taskDoc,
          {
            created: serverTimestamp(),
            updated: serverTimestamp(),
            dueDate: moment(this.payload.task.dueDate).endOf("day").toDate(),
            completedDate: null,
            createdBy: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
            lastUpdated: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
            completedBy: null,
            flags: {
              complete: false,
              system: false,
            },
            type: this.payload.task.type,
            status: status.value,
            priority: "normal",
            notes: this.payload.task.notes,
            relatedTo: {
              name: this.response.account.name,
              id: this.response.account.id,
              resource: "accounts",
            },
            contact,
            assignedTo: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
            externalIDs: {
              actionListID: this.$router.currentRoute.params.id,
            },
            users,
          },
          { merge: true }
        );
        batch.set(
          doc(collection(firestore, "ledger")),
          {
            created: serverTimestamp(),
            date: serverTimestamp(),
            relatedID: taskDoc.id,
            relatedTo: "tasks",
            type: "status",
            action: `<span class="text-capitalize">${this.getUser.name.first} ${
              this.getUser.name.last
            }</span> created task for <span class="font-weight-bold text-capitalize">${
              this.getUser.name.first
            } ${
              this.getUser.name.last
            }</span> | Status: <span class="font-weight-bold" style="color: ${
              status.color
            };">${
              status.text
            }</span> | <span class="text-capitalize">related to</span>: <span class="text-capitalize">${
              this.response.actionList.assignedTo
            }</span> (${moment(this.response.actionList.created).format(
              "MM/DD/YYYY"
            )}) - Action List`,
            user: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
          },
          { merge: true }
        );
        batch.set(
          doc(
            firestore,
            "brandStats",
            `${this.response.brandMetrics.account.id}_${this.response.brandMetrics.brand.id}`
          ),
          {
            metrics: {
              last: {
                task: serverTimestamp(),
              },
            },
            updated: serverTimestamp(),
          },
          { merge: true }
        );
      }

      // Action list
      const accounts = this.response.actionList.accounts.filter(
        (account) => account.id !== this.getActionListRecord.accountID
      );
      let selectedAccount = this.response.actionList.accounts.filter(
        (account) => account.id === this.getActionListRecord.accountID
      )[0];

      let updatedAccount = {
        id: selectedAccount.id,
        dueDate: moment(selectedAccount.dueDate.seconds * 1000).toDate(),
        completedDate: moment().toDate(),
        completedBy: {
          name: this.getUser.name,
          uid: this.getUser.uid,
        },
        viewed: moment().toDate(),
        name: selectedAccount.name,
        disposition: this.payload.updates.disposition,
        sentiment: disposition.sentiment,
        complete: true,
      };

      let status = this.response.actionList.status.value;
      if (this.response.actionList.status.value === "noAction") {
        const oldStatus = this.getActionListStatus.find(
            (option) => option.value === "noAction"
          ),
          newStatus = this.getActionListStatus.find(
            (option) => option.value === "inProgress"
          );
        status = newStatus.value;
        batch.set(
          doc(collection(firestore, "ledger")),
          {
            created: serverTimestamp(),
            date: serverTimestamp(),
            relatedID: actionListDoc.id,
            type: "status",
            action: `<span class="text-capitalize">${this.getUser.name.first} ${this.getUser.name.last}</span> <span class="text-uppercase font-weight-bold" style="color: ${this.$vuetify.theme.themes.dark.update};">updated</span> this Action List | <span class="text-capitalize">status</span>, <span class="text-uppercase">old</span>: <span class="font-weight-bold" style="color: ${oldStatus.color};">${oldStatus.text}</span> => <span class="text-uppercase font-weight-bold">new</span>: <span class="font-weight-bold" style="color: ${newStatus.color};">${newStatus.text}</span>`,
            user: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
          },
          { merge: true }
        );
      }

      batch.set(
        actionListDoc,
        {
          updated: serverTimestamp(),
          lastUpdated: {
            name: this.getUser.name,
            uid: this.getUser.uid,
          },
          accounts: [...accounts, updatedAccount],
          metrics: {
            complete: increment(1),
            pending: increment(-1),
            success: increment(disposition.sentiment ? 1 : 0),
            failure: increment(!disposition.sentiment ? 1 : 0),
          },
          status,
        },
        { merge: true }
      );

      await batch.commit();
      this.loading.submit = false;
      this._actionList({
        dialog: false,
        related: null,
      });
    },
    /* Main */
    async setup() {
      this.loading.overall = true;

      await this.getBrandAndAccount();
      await this.getActionList();
      this.loading.overall = false;
    },
    traverse(action, params) {
      let link;
      const dialpadActive = getValue(remoteConfig, "dialpad").asBoolean();

      switch (action) {
        case "submit":
          this.submit();
          break;
        case "call":
          if (dialpadActive) {
            this.startCall(params);
          } else {
            window.location.href = `tel://${
              this.getCountryCodes.find(
                (option) =>
                  option.name ===
                  this.response.account.location.shipping.countryID
              ).countryCode
            }${phoneNumber}`;
          }
          break;
        case "salesOrder-new":
          this.payload.clicked = true;
          link = this.$router.resolve({
            name: "salesOrder-new",
            params: {
              brandID: this.response.brandMetrics.brand.id,
              accountID: this.response.brandMetrics.account.id,
              countryID: this.response.brandMetrics.location.shipping.countryID,
              stateID:
                this.response.brandMetrics.location.shipping.state.stateID,
              brandStatsID: this.response.brandMetrics.id,
            },
          });
          window.open(link.href, "_blank");
          break;
      }
    },
    async getBrandAndAccount() {
      this.loading.account = true;
      this.reset();

      await this.getAccount();
      this.getContacts();
      await this.getBrandMetrics();

      this.loading.account = false;
    },
    async startCall(phoneNumber) {
      this.loading.call = true;
      const outboundCall = httpsCallable(functions, "outboundCall");

      const countryDoc = doc(
        firestore,
        "countries",
        this.response.account.location.shipping.countryID
      );
      const countryResponse = await getDoc(countryDoc);

      if (countryResponse.exists()) {
        const countryCode = this.response.account.location.shipping.countryID
          ? this.getCountryCodes.find(
              (option) => option.name === countryResponse.data().name
            ).countryCode
          : "1";
        await outboundCall({
          email: this.getUser.email,
          userPhoneNumber: this.getUser.phoneNumber,
          contactPhoneNumber: `+${countryCode}${phoneNumber}`,
        });
      }

      this.loading.call = false;
    },
    /* Misc */
    reset() {
      this.payload.updates = {
        disposition: null,
      };
      this.payload.task = {
        notes: "",
        contact: null,
        dueDate: null,
        type: "call",
      };
      this.payload.clicked = false;
    },
  },
  computed: {
    ...mapGetters([
      // App
      "getAppName",
      "getUser",
      "getCountryCodes",
      // Account
      "getAccountStatusGroups",
      // Contacts
      "getContactTitles",
      "getContactPreferences",
      // Tasks
      "getTaskTypes",
      "getTaskStatus",
      // Action List
      "getActionListRecord",
      "getActionListFields",
      "getActionListStatus",
      "getActionListDispositions",
    ]),
    today() {
      return moment().format("YYYY-MM-DD");
    },
    formattedDate() {
      return this.followUpDateSet
        ? moment(this.payload.task.dueDate).format("MM/DD/YYYY")
        : "-";
    },
    actionListType() {
      return this.response.actionList?.type || null;
    },
    dispositions() {
      return this.response.actionList
        ? this.getActionListDispositions.filter((disposition) =>
            disposition.type.includes(this.actionListType?.value)
          )
        : [];
    },
    conditions() {
      return this.getActionListRecord
        ? this.getActionListRecord.conditions.map((condition) => {
            return {
              field: `${condition.field}${
                condition.operator ? ` ${condition.operator}` : ":"
              } ${condition.value}`,
              account: this.response.brandMetrics[condition.fieldValue],
            };
          })
        : [];
    },
    // Update
    dispositionSet() {
      return this.payload.updates.disposition !== null ? true : false;
    },
    selectedDisposition() {
      return this.dispositionSet
        ? this.getActionListDispositions.find(
            (option) => option.value === this.payload.updates.disposition
          )
        : null;
    },
    // Task
    followUpDateSet() {
      return this.payload.task.dueDate ? true : false;
    },
    taskNotesSet() {
      return this.payload.task.notes.length > 0 ? true : false;
    },
    taskSet() {
      return this.followUpDateSet && this.taskNotesSet ? true : false;
    },
    orderButtonClicked() {
      return this.payload.clicked;
    },
    secondaryStep() {
      switch (this.payload.updates.disposition) {
        case "callBack":
          return this.taskSet ? true : false;
          break;
        case "samples_yes":
        case "firstOrder":
          return this.orderButtonClicked ? true : false;
          break;
        case "notInterested":
          return true;
          break;
      }
    },
    invalidAccountUpdate() {
      return this.dispositionSet && this.secondaryStep ? false : true;
    },
  },
};
</script>

<style></style>
