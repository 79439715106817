const state = {
  user: null,
  oAuthToken: null,
  accountTypes: [
    {
      text: "admin",
      value: 4,
      desc: "Full visibility, like a manager but does not need to have views set.",
      color: "black",
    },
    {
      text: "manager",
      value: 3,
      desc: "Has more permissions than a standard user, can approve orders/submit payments/etc.",
      color: "green",
    },
    {
      text: "user",
      value: 2,
      desc: "Standard user, will need to request manager to approve orders/etc.",
      color: "blue",
    },
    {
      text: "vendor",
      value: 1,
      desc: "3rd party given access to your MyTrace instance. Account search bar & most features will be hidden/disabled.",
      color: "grey",
    },
  ],
  mytracePermissions: [
    {
      text: "Add raw materials to a location without a raw material order",
      desc: "Allows the user to manually add raw materials to a location. Normally only available for admin users.",
      value: "rawMaterials",
    },
    {
      text: "Edit sales order line items",
      desc: "Only manager+ users can edit order line items once the status is >= accepted",
      value: "lineItems",
    },
    {
      text: "Add payments to sales orders",
      desc: "Only manager+ or users with the 'accounting' role can add payments",
      value: "payments",
    },
    {
      text: "Export data",
      desc: "By default, only manager+ can export data from mytrace.",
      value: "exportData",
    },
  ],
  departments: [
    { text: "Admin", value: "admin", sort: 1 },
    { text: "Support", value: "support", sort: 2 },
    { text: "IT/Tech", value: "tech", sort: 3 },
    { text: "Accounting/Finances", value: "accounting", sort: 4 },
    { text: "Legal/Compliance", value: "legal", sort: 5 },
    { text: "Manufacturing", value: "manufacturing", sort: 6 },
    { text: "Logistics/Delivery", value: "delivery", sort: 7 },
    { text: "Sales", value: "sales", sort: 8 },
    { text: "Graphic Design/Creative", value: "creative", sort: 9 },
    { text: "Marketing", value: "marketing", sort: 10 },
  ],
  userRoles: [
    { text: "Account Executive", value: "accountExecutive" },
    { text: "Account Manager", value: "accountManager" },
    { text: "Brand Ambassador", value: "brandAmbassador" },
    { text: "Event Manager", value: "eventManager" },
    { text: "Ticket Rep", value: "ticketRep" },
    { text: "Ticket Manager", value: "ticketManager" },
    { text: "Inventory Manager", value: "inventoryManager" },
    { text: "Accounting", value: "accounting" },
    { text: "Driver", value: "driver" },
  ],
  userPresets: [
    {
      text: "Inventory/Warehouse",
      value: "inventoryManager",
      accountType: 2,
      desc: "Manages location inventory & order fulfillment",
      department: "manufacturing",
      role: null,
      permissions: ["lineItems"],
      dashboards: ["fulfillment"],
      views: [
        "tasks",
        "locations",
        "testingLabs",
        "rawMaterialVendors",
        "rawMaterialOrders",
        "draftOrders",
        "salesOrders",
        "deliveredOrders",
        "reports-overview",
      ],
    },
    {
      text: "Manufacturing/Work Orders",
      value: "workOrders",
      accountType: 2,
      desc: "Manages work orders & manufacturing products",
      department: "manufacturing",
      permissions: [],
      role: null,
      dashboards: ["manufacturing"],
      views: ["tasks", "locations", "rawMaterialOrders"],
    },
    {
      text: "Account Manager",
      value: "salesRep",
      desc: "Manages Account Executives, Sales",
      accountType: 3,
      department: "sales",
      role: "accountManager",
      permissions: [],
      dashboards: ["sales manager"],
      views: [
        "tasks",
        "parentAccounts",
        "accounts",
        "account-onboarding",
        "draftOrders",
        "salesOrders",
        "deliveredOrders",
        "events-overview",
        "budtender-training",
        "reports-overview",
      ],
    },
    {
      text: "Account Executive",
      value: "accountExecutive",
      accountType: 2,
      desc: "Manages Accounts, creates orders & supports growth with trainings/store events",
      department: "sales",
      role: "accountExecutive",
      permissions: [],
      dashboards: ["sales rep"],
      views: [
        "tasks",
        "parentAccounts",
        "accounts",
        "account-onboarding",
        "draftOrders",
        "salesOrders",
        "deliveredOrders",
        "events-overview",
        "budtender-training",
        "reports-overview",
      ],
    },
    {
      text: "Accounting",
      value: "accounting",
      desc: "Apply payments/credits, Create AR reports & manage financies",
      department: "accounting",
      role: "accounting",
      accountType: null,
      permissions: [],
      dashboards: [],
      views: [
        "tasks",
        "vendorOrders",
        "customProducts",
        "customOrders",
        "rawMaterialOrders",
        "parentAccounts",
        "accounts",
        "salesOrders",
        "deliveredOrders",
        "promotions-overview",
        "reports-overview",
      ],
    },
    {
      text: "Support/Returns",
      value: "support",
      desc: "Manage Support Tickets, handle returns/defects from Accounts & misc support",
      department: "support",
      accountType: 2,
      role: "ticketManager",
      permissions: [],
      dashboards: [],
      views: [
        "tasks",
        "accounts",
        "salesOrders",
        "events-overview",
        "supportTickets-overview",
        "reports-overview",
      ],
    },
    {
      text: "Event Manager",
      value: "eventManager",
      desc: "Manage Store Events & manage Brand Ambassadors",
      accountType: 3,
      department: "support",
      role: "eventManager",
      permissions: [],
      dashboards: ["events"],
      views: [
        "tasks",
        "projects",
        "expenses",
        "accounts",
        "events-overview",
        "reports-overview",
      ],
    },
    {
      text: "Brand Ambassador",
      value: "brandAmbassador",
      desc: "Attend store events & educate customers in-store",
      accountType: 2,
      department: "support",
      role: "brandAmbassador",
      permissions: [],
      dashboards: ["brand ambassador"],
      views: ["tasks", "accounts", "events-overview"],
    },
  ],
  dashboards: [
    {
      text: "Vendor Orders",
      desc: "Manage all active vendor orders, grouped by state",
      value: "vendor orders",
    },
    {
      text: "Manufacturing",
      desc: "View incoming raw material / active work orders by selected location",
      value: "manufacturing",
    },
    {
      text: "Fulfillment",
      desc: "Shows in-progress orders & updates in real-time as changes are made",
      value: "fulfillment",
    },
    {
      text: "Sales Rep",
      desc: "View managed accounts/orders/store events/etc to a sales rep. Shows MTD orders vs. goal, current accounts receivable...",
      value: "sales rep",
    },
    {
      text: "Lead Prospector",
      desc: "Similar to Sales Rep dashboard, but focused on leads*",
      value: "lead prospector",
    },
    {
      text: "Sales Manager",
      desc: "View the details of a sales rep's dashboard. Includes option to set goals for all reps in the selected state",
      value: "sales manager",
    },
    {
      text: "Brand Ambassador",
      desc: "Filters active store events assigned to the related user",
      value: "brand ambassador",
    },
    {
      text: "Event Manager",
      desc: "View all store events in the user's associated state. Filter by events where 'Approval Required' & finalize events in 'Ready for Review'",
      value: "events",
    },
    {
      text: "Projects",
      desc: "View active projects associated with this user. Includes assigned tasks",
      value: "projects",
    },
    {
      text: "Driver",
      desc: "View assigned delivery routes based on selected day. Includes option to clock in/out",
      value: "driver",
    },
  ],
};

const actions = {
  async _user({ commit }, payload) {
    commit("setUser", payload);
  },
  async _oAuthToken({ commit }, payload) {
    commit("setOAuthToken", payload);
  },
};

const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setOAuthToken(state, payload) {
    state.oAuthToken = payload;
  },
};

const getters = {
  getUser(state) {
    return state.user;
  },
  getAccountTypes(state) {
    return state.accountTypes;
  },
  getUserDashboards(state) {
    return state.dashboards;
  },
  getUserRoles(state) {
    return state.userRoles;
  },
  getUserPermissions() {
    return state.mytracePermissions;
  },
  getUserPresets(state) {
    return state.userPresets;
  },
  getDepartments(state) {
    return state.departments;
  },
  getOAuthToken(state) {
    return state.oAuthToken;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
