<template>
  <v-form>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-3">
              <v-toolbar-title>Preparation</v-toolbar-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col v-if="getRightDrawer.data.flags.liveTraining" cols="12">
                <v-text-field
                  label="Training Type"
                  :color="$vuetify.theme.themes.dark.primary"
                  :value="getRightDrawer.data.trainingType | capitalize"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Estimated Budtenders"
                  :color="$vuetify.theme.themes.dark.primary"
                  :value="
                    getRightDrawer.data.preparation.amounts.budtenders
                      | addComma
                  "
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  label="Providing Meal?"
                  :color="$vuetify.theme.themes.dark.primary"
                  :value="getRightDrawer.data.preparation.meal | capitalize"
                  hide-details
                  readonly
                >
                  <template v-slot:append>
                    <v-icon
                      v-if="getRightDrawer.data.preparation.meal === 'yes'"
                      :color="$vuetify.theme.themes.dark.success"
                      >{{ $vuetify.icons.values.submit }}</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  label="Meal Budget"
                  :color="$vuetify.theme.themes.dark.primary"
                  :value="
                    getRightDrawer.data.preparation.meal !== 'no'
                      ? new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(getRightDrawer.data.preparation.amounts.meal)
                      : 'No meal being provided'
                  "
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  label="Providing Swag?"
                  :color="$vuetify.theme.themes.dark.primary"
                  :value="getRightDrawer.data.preparation.swag | capitalize"
                  hide-details
                  readonly
                >
                  <template v-slot:append>
                    <v-icon
                      v-if="getRightDrawer.data.preparation.swag === 'yes'"
                      :color="$vuetify.theme.themes.dark.success"
                      >{{ $vuetify.icons.values.submit }}</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  label="Swag Budget"
                  :color="$vuetify.theme.themes.dark.primary"
                  :value="
                    getRightDrawer.data.preparation.swag !== 'no'
                      ? getRightDrawer.data.preparation.amounts.swag
                      : 'No swag being provided'
                  "
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  label="Samples sent?"
                  :color="$vuetify.theme.themes.dark.primary"
                  :value="getRightDrawer.data.flags.sampleStatus"
                  hide-details
                  readonly
                >
                  <template v-slot:append>
                    <v-icon
                      v-if="getRightDrawer.data.preparation.samples === 'yes'"
                      :color="$vuetify.theme.themes.dark.success"
                      >{{ $vuetify.icons.values.submit }}</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  label="Sample Budget"
                  :color="$vuetify.theme.themes.dark.primary"
                  :value="
                    getRightDrawer.data.preparation.samples !==
                    'samples_received'
                      ? getRightDrawer.data.preparation.amounts.samples
                      : 'Received before training'
                  "
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-text-field
          label="Account"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.account.name | capitalize"
          :hint="
            ('Account Executive: ' +
              getRightDrawer.data.account.accountExecutive.name)
              | capitalize
          "
          persistent-hint
          readonly
        >
          <template v-slot:append-outer>
            <v-btn
              small
              rounded
              :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
              @click="traverse('account-view')"
            >
              <span class="mr-1">view</span>
              <v-icon>{{ $vuetify.icons.values.view }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="12"
        sm="12"
        v-if="getRightDrawer.data.flags.liveTraining"
      >
        <v-text-field
          label="Trainer"
          :color="$vuetify.theme.themes.dark.primary"
          :value="`${getRightDrawer.data.trainer}` | capitalize"
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        :md="!getRightDrawer.data.flags.liveTraining ? 6 : 12"
        sm="12"
      >
        <v-text-field
          label="Date"
          :color="$vuetify.theme.themes.dark.primary"
          :value="dateForTraining(getRightDrawer.data)"
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        :md="!getRightDrawer.data.flags.liveTraining ? 6 : 12"
        sm="12"
      >
        <v-text-field
          label="Training Format"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            getRightDrawer.data.flags.liveTraining
              ? this.getBudtenderTrainingFormats.find(
                  (type) => getRightDrawer.data.format === type.text
                ).text
              : 'Offline'
          "
          hide-details
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-text-field
          label="Address"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            `${getRightDrawer.data.account.location.address}, ${
              getRightDrawer.data.account.location.city
            }, ${getRightDrawer.data.account.location.state.abbreviation.toUpperCase()} ${
              getRightDrawer.data.account.location.zipCode
            }` | capitalize
          "
          hide-details
          readonly
        >
          <template v-slot:append-outer>
            <v-btn
              small
              rounded
              :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
              @click="traverse('address')"
            >
              <span class="mr-1">map</span>
              <v-icon>{{ $vuetify.icons.values.map }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-textarea
          label="Notes"
          outlined
          no-resize
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.notes"
          hide-details
          readonly
          :disabled="getRightDrawer.data.notes.length === 0"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "BudtenderTrainingPreview",
  data: () => ({}),
  methods: {
    ...mapActions(["_rightDrawer"]),
    /* API */
    // GET
    // POST
    /* Main */
    async traverse(action, params) {
      let link;
      switch (action) {
        case "account-view":
          if (this.$router.currentRoute.name === "accounts-view") {
            this.$router.push({
              name: "accounts-view",
              params: {
                id: this.getRightDrawer.data.account.id,
              },
            });
            this.$router.go();
          } else {
            this.$router.push({
              name: "accounts-view",
              params: {
                id: this.getRightDrawer.data.account.id,
              },
            });
          }
          break;
        case "address":
          window.open(
            `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
              this.address
            )}`,
            "_blank"
          );
          break;
      }
    },
    /* Misc */
    dateForTraining(training) {
      const date = moment(training.date).format("MM/DD/YYYY");
      let time = {};
      if (training.flags.liveTraining) {
        time = {
          start: moment(training.time.start, "hh:mm A").format("h:mm A"),
          end: moment(training.time.end, "hh:mm A").format("h:mm A"),
        };
      }

      switch (training.format) {
        case "In Person":
        case "Online":
          return `${date} @ ${time.start} - ${time.end}`;
          break;
        default:
          return `${date}`;
          break;
      }
    },
  },
  computed: {
    ...mapGetters([
      "getAppName",
      "getUser",
      "getRightDrawer",
      "getBudtenderTrainingFormats",
    ]),
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
    address() {
      return `${this.getRightDrawer.data.account.location.address}, ${
        this.getRightDrawer.data.account.location.city
      }, ${this.getRightDrawer.data.account.location.state.abbreviation.toUpperCase()} ${
        this.getRightDrawer.data.account.location.zipCode
      }`;
    },
  },
};
</script>
