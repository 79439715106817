<template>
  <router-link
    :to="{ name: 'salesOrder-view', params: { id: id } }"
    :target="openInNewTab ? '_blank' : '_self'"
  >
    <slot />
  </router-link>
</template>

<script>
// Libraries
import { mapGetters } from "vuex";

// Components

export default {
  name: "SalesOrderViewerLabel",
  data() {
    return {};
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    openInNewTab: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    /* API */
    // GET
    // POST
    /* */
  },
  computed: {
    ...mapGetters([
      // Main
      "getAppName",
      "getUser",
    ]),
  },
};
</script>
