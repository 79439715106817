import { colors } from "vuetify/lib";

const state = {
  statuses: [
    {
      text: "Cancelled",
      value: "cancelled",
      color: colors.red.base,
      sort: 1,
    },
    {
      text: "No Action",
      value: "noAction",
      color: colors.grey.base,
      sort: 2,
    },
    {
      text: "In-Progress",
      value: "inProgress",
      color: colors.blue.base,
      sort: 3,
    },
    {
      text: "Ready for Review",
      value: "readyForReview",
      color: colors.blueGrey.base,
      sort: 4,
    },
    {
      text: "Complete",
      value: "complete",
      color: colors.green.base,
      sort: 5,
    },
  ],
  dispositions: [
    {
      text: "No Answer/Unavailable/Busy",
      value: "callBack",
      desc: "Set a follow-up task for a future date",
      type: ["leads", "samples", "active"],
      task: true,
      sentiment: false,
    },
    {
      text: "Buyer Interested - Samples Sent",
      value: "samples_yes",
      desc: "When the order is delivered, a follow up task will be created to follow up in 2 weeks",
      type: ["leads", "samples", "active"],
      task: false,
      sentiment: true,
    },
    {
      text: "1st Order Placed",
      value: "firstOrder",
      desc: "-",
      type: ["leads", "samples", "active"],
      task: false,
      sentiment: true,
    },
    {
      text: "Not Interested",
      value: "notInterested",
      desc: "-",
      type: ["leads", "samples", "active"],
      task: false,
      sentiment: false,
    },
  ],
  fields: [
    {
      text: "Last Task",
      value: "lastTask",
      type: "date",
      prefix: "",
      suffix: "days",
      operators: [],
    },
    {
      text: "Last Order",
      value: "lastOrder",
      type: "date",
      prefix: "",
      suffix: "days",
      operators: [],
    },
    {
      text: "Last Payment",
      value: "lastPayment",
      type: "date",
      prefix: "",
      suffix: "days",
      operators: [],
    },
    {
      text: "Last Store Event",
      value: "lastStoreEvent",
      type: "date",
      prefix: "",
      suffix: "days",
      operators: [],
    },
    {
      text: "Last Viewed",
      value: "viewed",
      type: "date",
      prefix: "",
      suffix: "days",
      operators: [],
    },
    {
      text: "Balance",
      value: "balance",
      type: "currency",
      prefix: "$",
      suffix: "",
      operators: [">", "<", ">=", "<="],
    },
    {
      text: "Total Sales",
      value: "totalSales",
      type: "currency",
      prefix: "$",
      suffix: "",
      operators: [],
    },
  ],
  operators: [
    {
      text: "Greater Than",
      value: ">",
    },
    {
      text: "Less Than",
      value: "<",
    },
    {
      text: "Greater Than or equal to",
      value: ">=",
    },
    {
      text: "Less than or equal to",
      value: "<=",
    },
    {
      text: "Equals",
      value: "==",
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getActionListStatus(state) {
    return state.statuses;
  },
  getActionListDispositions(state) {
    return state.dispositions;
  },
  getActionListFields(state) {
    return state.fields;
  },
  getActionListOperators(state) {
    return state.operators;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
