import clients from "./clients/phase3_clients";
import actionLists from "./actionLists/phase3_actionLists";
import discounts from "./discounts/phase3_discounts";
import salesOrders from "./salesOrders/phase3_salesOrders";

export default {
  modules: {
    clients,
    actionLists,
    discounts,
    salesOrders,
  },
};
