<template>
  <v-form>
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Status"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.status"
          readonly
        >
          <template v-slot:prepend-inner>
            <v-badge
              inline
              dot
              :color="getRightDrawer.data.statusColor"
            ></v-badge>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Task Type"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.type"
          readonly
        ></v-text-field>
      </v-col>
      <v-col v-if="getRightDrawer.data.relatedTo" cols="12" md="12" sm="12">
        <v-text-field
          label="Related To"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.relatedTo.name | capitalize"
          readonly
        >
          <template
            v-slot:append-outer
            v-if="
              getRightDrawer.data.relatedTo.resource &&
              getRightDrawer.data.relatedTo.resource.length > 0
            "
          >
            <v-btn
              small
              rounded
              :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
              @click="
                traverse('relatedTo-view', {
                  resourse: getRightDrawer.data.relatedTo.resource,
                  id: getRightDrawer.data.relatedTo.id,
                })
              "
            >
              <v-icon class="mr-1">{{ $vuetify.icons.values.view }}</v-icon>
              <span>view</span>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12" md="3" sm="12">
        <v-text-field
          label="Priority"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.priority | capitalize"
          readonly
        >
          <template v-slot:prepend>
            <v-icon
              v-if="getRightDrawer.data.priority.toLowerCase() === 'high'"
              :color="$vuetify.theme.themes.dark.error"
              >mdi-fire</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-text-field
          label="Due Date"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.dueDate"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="Assigned To"
          :color="$vuetify.theme.themes.dark.primary"
          :value="`${getRightDrawer.data.assignedTo}` | capitalize"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-textarea
          label="Notes"
          outlined
          no-resize
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.notes"
          readonly
          :disabled="getRightDrawer.data.notes.length === 0"
        ></v-textarea>
      </v-col>
      <v-col cols="6" md="6">
        <v-text-field
          label="Created"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.created | moment('MM/DD/YYYY @ hh:mm A')"
          persistent-hint
          :hint="`created by: ${getRightDrawer.data?.createdBy}` | capitalize"
          readonly
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="12">
        <v-text-field
          label="Updated"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.updated | moment('MM/DD/YYYY @ hh:mm A')"
          persistent-hint
          :hint="
            `last updated: ${getRightDrawer.data?.lastUpdated}` | capitalize
          "
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "TaskPreview",
  methods: {
    ...mapActions(["_rightDrawer"]),
    /* API */
    // GET
    // POST
    /* Main */
    async traverse(action, params) {
      let link;
      switch (action) {
        case "relatedTo-view":
          if (
            this.$router.currentRoute.name ===
            `${this.getRightDrawer.data.relatedTo.resource}-view`
          ) {
            this.$router.push({
              name: `${this.getRightDrawer.data.relatedTo.resource}-view`,
              params: {
                id: params.id,
              },
            });
            this.$router.go();
          } else {
            this.$router.push({
              name: `${this.getRightDrawer.data.relatedTo.resource}-view`,
              params: {
                id: params.id,
              },
            });
          }
          break;
      }
    },
    /* Misc */
  },
  computed: {
    ...mapGetters([
      "getAppName",
      "getUser",
      "getTaskStatus",
      "getRightDrawer",
      "getTaskTypes",
    ]),
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
  },
};
</script>
