import { colors } from "vuetify/lib";

const state = {
  status: [
    {
      text: "Cancelled",
      value: "cancelled",
      sort: 1,
      complete: true,
      color: colors.red.base,
    },
    {
      text: "Not Started",
      value: "notStarted",
      sort: 2,
      complete: false,
      color: colors.grey.base,
    },
    {
      text: "In Progress",
      value: "inProgress",
      sort: 3,
      complete: false,
      color: colors.yellow.darken2,
    },
    {
      text: "Under Review",
      value: "review",
      sort: 4,
      complete: false,
      color: colors.blue.base,
    },
    {
      text: "Paused",
      value: "paused",
      sort: 5,
      complete: false,
      color: colors.deepPurple.base,
    },
    {
      text: "Complete",
      value: "complete",
      complete: true,
      sort: 6,
      color: colors.green.base,
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getProjectStatus(state) {
    return state.status;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
