import { colors } from "vuetify/lib";

const state = {
  rawMaterialMeasurementTypes: ["grams", "liters", "units"],
  orderStatus: [
    {
      text: "Complete",
      value: "complete",
      color: colors.green.base,
    },
    {
      text: "In-Progress",
      value: "in progress",
      color: colors.blue.base,
    },
    {
      text: "Cancelled",
      value: "cancelled",
      color: colors.red.base,
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getRawMaterialMeasurementTypes(state) {
    return state.rawMaterialMeasurementTypes;
  },
  getRawMaterialOrderStatus(state) {
    return state.orderStatus;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
