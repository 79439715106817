const state = {
  paymentTypes: [
    { text: "Cash", value: "cash" },
    { text: "Check", value: "check" },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getWhiteLabelOrderPaymentTypes(state) {
    return state.paymentTypes;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
