<template>
  <v-container fluid class="pa-0 rounded-lg">
    <v-card flat width="auto" class="rounded-lg">
      <v-card-text class="pa-0">
        <v-sheet
          style="
            position: sticky;
            top: 55px;
            z-index: 2;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
              rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
          "
          elevation="3"
        >
          <v-tabs
            grow
            :color="notificationTabColor"
            v-model="activeTab.notifications"
            show-arrows
            :background-color="'white'"
          >
            <v-tab style="opacity: 1 !important">
              <v-badge
                inline
                :color="$vuetify.theme.themes.dark.error"
                :content="unreadNotifications.length || '0'"
              ></v-badge>
              <span
                class="text-uppercase mx-1 text-caption text-md-button font-weight-bold font-weight-md-normal"
                >unread</span
              >
              <v-icon :size="isMobileDevice ? 20 : 24">mdi-bell-ring</v-icon>
            </v-tab>
            <v-tab style="opacity: 1 !important">
              <v-badge
                inline
                :color="$vuetify.theme.themes.dark.primary"
                :content="allNotifications.length || '0'"
              ></v-badge>
              <span
                class="text-uppercase mx-1 text-caption text-md-button font-weight-bold font-weight-md-normal"
                >all</span
              >
              <v-icon :size="isMobileDevice ? 20 : 24">mdi-bell-outline</v-icon>
            </v-tab>
            <v-tab style="opacity: 1 !important">
              <v-badge
                inline
                :color="$vuetify.theme.themes.dark.ledger"
                :content="sentNotifications.length || '0'"
              ></v-badge>
              <span
                class="text-uppercase mx-1 text-caption text-md-button font-weight-bold font-weight-md-normal"
                >sent</span
              >
              <v-icon :size="isMobileDevice ? 20 : 24"
                >mdi-receipt-text-send</v-icon
              >
            </v-tab>
          </v-tabs>
        </v-sheet>
        <v-list class="py-0">
          <v-list-item v-if="notifications.length === 0">
            <v-list-item-content>
              <v-list-item-title class="text-center">
                <span class="text--disabled"
                  >No{{
                    this.activeTab.notifications === 0
                      ? " unread"
                      : this.activeTab.notifications === 2
                      ? " sent"
                      : ""
                  }}
                  notifications.</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item-group v-else>
            <template v-for="(notification, index) in notifications">
              <v-list-item
                three-line
                :key="notification.id"
                :class="activeTab.notifications === 2 ? 'px-4' : 'px-4 px-md-3'"
                @click="traverse('notification-view', notification)"
              >
                <v-list-item-icon
                  v-if="activeTab.notifications !== 2"
                  class="mr-0"
                >
                  <v-badge
                    v-if="!notification.viewed"
                    dot
                    inline
                    :color="$vuetify.theme.themes.dark.review"
                  ></v-badge>
                </v-list-item-icon>
                <v-hover v-slot="{ hover }">
                  <v-fade-transition leave-absolute>
                    <v-list-item-content>
                      <div class="d-flex justify-space-between">
                        <div>
                          <v-icon size="12">{{
                            notification.icon ?? "mdi-bell"
                          }}</v-icon>
                          <span
                            class="ml-1 text--secondary font-weight-bold text-caption text-capitalize"
                            >{{ notification.type }}</span
                          >
                        </div>
                        <div>
                          <v-icon size="12">mdi-clock</v-icon>
                          <span
                            class="ml-1 text--secondary font-weight-bold text-caption text-capitalize"
                            >{{
                              notification.created | moment("M/D/YY @ h:mm A")
                            }}</span
                          >
                        </div>
                      </div>
                      <v-list-item-title>
                        <span class="text-wrap font-weight-medium"
                          >{{ notification.notes }}
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <div
                          style="gap: 8px"
                          class="d-flex align-center justify-space-between"
                        >
                          <span
                            class="font-weight-bold text-wrap"
                            style="min-height: 20px !important"
                          >
                            {{ notification.name }}</span
                          >
                          <div>
                            <v-btn
                              plain
                              x-small
                              :color="$vuetify.theme.themes.dark.review"
                              v-if="hover || $vuetify.breakpoint.mdAndDown"
                              @click.stop="copyDetails(notification)"
                            >
                              <v-icon size="12">mdi-content-copy</v-icon>
                              <span
                                :style="`color: ${$vuetify.theme.themes.dark.review}`"
                                class="ml-1 font-weight-bold text-caption text-capitalize"
                                >Copy</span
                              >
                            </v-btn>
                            <v-edit-dialog
                              large
                              persistent
                              cancel-text="Cancel"
                              save-text="Send"
                              @open="traverse('reply-show', notification)"
                              @save="traverse('reply-submit')"
                            >
                              <v-btn
                                plain
                                x-small
                                :color="$vuetify.theme.themes.dark.review"
                                v-if="hover || $vuetify.breakpoint.mdAndDown"
                              >
                                <v-icon size="12"
                                  >mdi-message-reply-text</v-icon
                                >
                                <span
                                  :style="`color: ${$vuetify.theme.themes.dark.review}`"
                                  class="ml-1 font-weight-bold text-caption text-capitalize"
                                  >Reply</span
                                >
                              </v-btn>
                              <template v-slot:input>
                                <v-text-field
                                  placeholder="Enter reply..."
                                  persistent-placeholder
                                  :background-color="
                                    replyMsgSet ? '' : 'yellow lighten-3'
                                  "
                                  :color="$vuetify.theme.themes.dark.primary"
                                  clearable
                                  autofocus
                                  @click:clear="payload.reply.msg = ''"
                                  v-model.trim="payload.reply.msg"
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </div>
                        </div>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <template
                          v-if="
                            activeTab.notifications === 2 && notification.viewed
                          "
                        >
                          <div
                            class="d-flex justify-space-between align-center"
                          >
                            <span
                              v-if="activeTab.notifications !== 2"
                              class="text-capitalize text-caption"
                              >created by: {{ notification.createdBy }}</span
                            >
                            <span v-else class="text-capitalize text-caption"
                              >sent to: {{ notification.assignedTo }}</span
                            >
                            <div
                              v-if="
                                activeTab.notifications === 2 &&
                                notification.viewed
                              "
                            >
                              <v-icon
                                :color="$vuetify.theme.themes.dark.success"
                                size="12"
                                >mdi-read</v-icon
                              >
                              <span
                                :style="`color: ${$vuetify.theme.themes.dark.success}`"
                                class="ml-1 font-weight-bold text-caption text-capitalize"
                                >{{
                                  notification.updated
                                    | moment("M/D/YY @ h:mm A")
                                }}</span
                              >
                            </div>
                          </div>
                        </template>
                        <div v-else class="d-flex justify-start">
                          <span
                            v-if="activeTab.notifications !== 2"
                            class="text-capitalize text-caption"
                            >created by: {{ notification.createdBy }}</span
                          >
                          <span v-else class="text-capitalize text-caption"
                            >sent to: {{ notification.assignedTo }}</span
                          >
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-fade-transition>
                </v-hover>
              </v-list-item>
              <v-divider :key="`${index}-divider`" />
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>

    <!-- Success Snackbar -->
    <v-snackbar
      :value="snackbar.active"
      :color="snackbar.color"
      timeout="2500"
      centered
      rounded="lg"
      transition="fade-transition"
    >
      <v-layout align-center justify-space-around>
        <v-icon class="pr-3" dark>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div class="text-subtitle-1 font-weight-bold">
            {{ snackbar.msg }}
          </div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </v-container>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import {
  // Auth
  auth,
  signOut,
  // Firestore
  firestore,
  collection,
  onSnapshot,
  getDoc,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
  query,
  where,
  or,
  writeBatch,
  limit,
  // Functions
  functions,
  httpsCallable,
  // Remote Config
  remoteConfig,
  fetchAndActivate,
  getValue,
} from "@/firebase/init";
import moment from "moment";
// Components

export default {
  name: "Notifications",
  data: () => ({
    loading: {
      submit: false,
    },
    activeTab: {
      notifications: 0,
    },
    response: {
      notifications: [],
    },
    payload: {
      reply: {
        notification: null,
        msg: "",
      },
    },
    snackbar: {
      msg: "",
      icon: "",
      color: "",
      active: "",
    },
  }),
  async created() {
    this.setup();
  },
  methods: {
    ...mapActions(["_rightDrawer"]),
    /* API */
    // GET
    async getNotifications() {
      this.response.notifications = [];
      const notificationsRef = collection(firestore, "notifications");
      const relatedUser = or(
        where("assignedTo.uid", "==", this.getUser.uid),
        where("createdBy.uid", "==", this.getUser.uid)
      );

      const q = query(notificationsRef, relatedUser);
      onSnapshot(q, (response) => {
        if (!response.empty) {
          this.response.notifications = response.docs
            .map((notification) => {
              return {
                id: notification.id,
                viewed: notification.data().viewed,
                createdBy: `${notification.data().createdBy.name.first} ${
                  notification.data().createdBy.name.last
                }`,
                assignedTo: `${notification.data().assignedTo.name.first} ${
                  notification.data().assignedTo.name.last
                }`,
                created: moment(
                  notification.data().created?.seconds * 1000
                ).toDate(),
                updated: moment(
                  notification.data().updated?.seconds * 1000
                ).toDate(),
                relatedTo: notification.data().relatedTo,
                notes: notification.data().notes,
                createdByUID: notification.data().createdBy.uid,
                assignedToUID: notification.data().assignedTo.uid,
                name: notification.data().relatedTo.name,
                type: notification.data().relatedTo.type,
                icon: notification.data().relatedTo.icon ?? null,
              };
            })
            .sort((a, b) => (a.created > b.created ? -1 : 1));
        } else {
          this.response.notifications = [];
        }
      });
    },
    // POST
    async reply() {
      const batch = writeBatch(firestore);
      this.loading.submit = true;
      const slackActive = await getValue(remoteConfig, "slack").asBoolean();

      let slackAPI = null;
      if (slackActive) {
        const integrationsRef = collection(firestore, "integrations"),
          related = where("platform", "==", "slack");
        const q = query(integrationsRef, related, limit(1));
        const response = await getDocs(q);
        if (!response.empty) {
          slackAPI = response.docs[0].data();
        }
      }

      if (this.payload.reply.msg.length > 0) {
        const userDoc = doc(
          firestore,
          "users",
          this.payload.reply.notification.createdByUID
        );
        const userResponse = await getDoc(userDoc);

        let ledgerEntries = [
          // Admin
          "task",
          "projects",
          // Sourcing
          "vendorOrder-productCategory",
          "vendorOrder-invoice",
          // Manufacturing
          "rawMaterialOrders",
          "productWorkOrder",
          // Sales
          "salesOrder",
          // Support
          "budtenderTrainings",
          "storeEvents",
          "supportTickets",
        ];

        batch.set(
          doc(collection(firestore, "notifications")),
          {
            created: serverTimestamp(),
            updated: serverTimestamp(),
            assignedTo: {
              name: {
                first: this.payload.reply.notification.createdBy.split(" ")[0],
                last: this.payload.reply.notification.createdBy.split(" ")[1],
              },
              uid: this.payload.reply.notification.createdByUID,
            },
            createdBy: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
            lastUpdated: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
            notes: this.payload.reply.msg,
            relatedTo: this.payload.reply.notification.relatedTo,
            viewed: false,
          },
          { merge: true }
        );
        if (
          ledgerEntries.includes(
            this.payload.reply.notification.relatedTo.resource
          )
        ) {
          batch.set(
            doc(collection(firestore, "ledger")),
            {
              created: serverTimestamp(),
              createdBy: {
                name: this.getUser.name,
                uid: this.getUser.uid,
              },
              date: serverTimestamp(),
              relatedID: this.payload.reply.notification.relatedTo.id,
              productWorkOrderID:
                this.payload.reply.notification.relatedTo.resource ===
                "productWorkOrder"
                  ? this.payload.reply.notification.relatedTo.id
                  : null,
              type: "notification",
              assignedTo: {
                name: {
                  first:
                    this.payload.reply.notification.createdBy.split(" ")[0],
                  last: this.payload.reply.notification.createdBy.split(" ")[1],
                },
                uid: this.payload.reply.notification.createdByUID,
              },
              notes: this.payload.reply.msg,
              action: `<span class="text-capitalize">${
                this.getUser.name.first
              } ${
                this.getUser.name.last
              }</span> sent a notification to <span class="text-capitalize">${
                this.payload.reply.notification.createdBy.split(" ")[0]
              } ${
                this.payload.reply.notification.createdBy.split(" ")[1]
              }</span> | <span class="text-capitalize">notes</span>: "${
                this.payload.reply.msg
              }"`,
              user: {
                name: this.getUser.name,
                uid: this.getUser.uid,
              },
            },
            { merge: true }
          );
        }

        if (slackAPI) {
          await this.sendSlackMessage({
            apiKey: slackAPI.apiKey,
            email: userResponse.data().email,
            text: `:bell: ${this.$options.filters.capitalize(
              this.getUser.name.first
            )} ${this.$options.filters.capitalize(
              this.getUser.name.last
            )} has sent you a new notification\nRecord: ${this.$options.filters.capitalize(
              this.payload.reply.notification.relatedTo.name
            )} | ${this.$options.filters.capitalize(
              this.payload.reply.notification.relatedTo.type
            )}\nNotes: "${this.payload.reply.msg}"`,
            postAt: moment().unix(),
            url: "https://slack.com/api/chat.postMessage",
          });
        }

        await batch.commit();
      } else {
        this.snackbar.active = true;
        this.snackbar = {
          active: true,
          msg: "A reply message must be set!",
          color: "red",
          icon: "mdi-warning",
        };
      }
      this.loading.submit = false;
    },
    async sendSlackMessage(payload) {
      const slackMessage = httpsCallable(functions, "slackMessage");
      await slackMessage(payload);
    },
    /* Main */
    async setup() {
      if (auth.currentUser) {
        await this.getNotifications();
      }
    },
    async traverse(action, params) {
      let link;
      switch (action) {
        case "notification-view":
          if (!params.viewed && this.activeTab.notifications !== 2) {
            await updateDoc(
              doc(firestore, "notifications", params.id),
              {
                updated: serverTimestamp(),
                lastUpdated: {
                  name: this.getUser.name,
                  uid: this.getUser.uid,
                },
                viewed: true,
              },
              { merge: true }
            );
          }
          if (
            this.$router.currentRoute.name ===
            `${params.relatedTo.resource}-view`
          ) {
            let routerParams = {
              id: params.relatedTo.id,
            };

            if (params.relatedTo.locationID) {
              routerParams.locationID = params.relatedTo.locationID;
            }

            this.$router.push({
              name: `${params.relatedTo.resource}-view`,
              params: {
                ...routerParams,
              },
            });
            this.$router.go();
          } else {
            let routerParams = {
              id: params.relatedTo.id,
            };

            if (params.relatedTo.locationID) {
              routerParams.locationID = params.relatedTo.locationID;
            }
            this.$router.push({
              name: `${params.relatedTo.resource}-view`,
              params: {
                ...routerParams,
              },
            });
          }
          break;
        case "reply-show":
          console.log(params);
          this.resetReply();
          this.payload.reply.notification = params;
          break;
        case "reply-submit":
          this.reply();
          break;
      }
    },
    async copyDetails(notification) {
      let textToCopy = `${notification.notes} | ${notification.name}`;

      try {
        await navigator.clipboard.writeText(textToCopy);

        this.snackbar = {
          active: true,
          msg: "Details copied to clipboard!",
          icon: "mdi-check-circle",
          color: "success",
        };
      } catch (error) {
        console.error(error.message);
        this.snackbar = {
          active: true,
          msg: "Error copying details to clipboard!",
          icon: "mdi-alert-circle",
          color: "error",
        };
      }

      setTimeout(() => {
        this.snackbar = {
          active: false,
          msg: "",
          icon: "",
          color: "",
        };
      }, 2500);
    },
    /* Misc */
    resetReply() {
      this.payload.reply = {
        notification: null,
        msg: "",
      };
    },
  },
  computed: {
    ...mapGetters([
      // App
      "getAppName",
      "getUser",
      "getRightDrawer",
    ]),
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
    // Notifications
    notificationTabColor() {
      switch (this.activeTab.notifications) {
        case 0:
          return this.$vuetify.theme.themes.dark.review;
          break;
        case 1:
          return this.$vuetify.theme.themes.dark.primary;
          break;
        case 2:
          return this.$vuetify.theme.themes.dark.ledger;
          break;
      }
    },
    unreadNotifications() {
      return this.response.notifications.filter(
        (notification) =>
          !notification.viewed &&
          notification.assignedToUID === this.getUser.uid
      );
    },
    allNotifications() {
      return this.response.notifications.filter(
        (item) => item.assignedToUID === this.getUser.uid
      );
    },
    sentNotifications() {
      return this.response.notifications.filter(
        (item) => item.createdByUID === this.getUser.uid
      );
    },
    notifications() {
      switch (this.activeTab.notifications) {
        case 0:
          return this.unreadNotifications;
          break;
        case 1:
          return this.allNotifications;
          break;
        case 2:
          return this.sentNotifications;
          break;
        default:
          return [];
          break;
      }
    },
    // Reply
    replyMsgSet() {
      return this.payload.reply.msg?.length > 0 ? true : false;
    },
  },
};
</script>

<style></style>
