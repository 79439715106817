import rawMaterials from "./rawMaterials/phase2_rawMaterials";
import locations from "./location/phase2_location";
import whiteLabelOrders from "./whiteLabelOrders/phase2_whiteLabelOrders";

export default {
  modules: {
    rawMaterials,
    locations,
    whiteLabelOrders,
  },
};
