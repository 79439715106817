<template>
  <v-snackbar
    v-if="isDownloadingUpdate"
    :value="isDownloadingUpdate"
    :color="$vuetify.theme.themes.dark.update"
    multi-line
    timeout="-1"
    bottom
    class="pb-7"
    rounded="lg"
  >
    <v-layout align-center justify-space-around>
      <v-icon class="pr-3" dark>mdi-update</v-icon>
      <v-layout column>
        <div>
          <strong>Downloading updates...</strong>
        </div>
        <div>
          <span> {{ getAppName }} is downloading new updates. </span>
        </div>
      </v-layout>
    </v-layout>
    <template v-slot:action>
      <v-container fluid class="pr-3">
        <v-progress-circular indeterminate size="28"></v-progress-circular>
      </v-container>
    </template>
  </v-snackbar>

  <v-overlay v-else-if="updateExists" :value="updateExists" z-index="9999">
    <v-snackbar
      :value="updateExists"
      color="primary"
      multi-line
      timeout="-1"
      bottom
      class="pb-7"
      rounded="lg"
    >
      <v-layout align-center justify-space-around>
        <v-icon class="pr-3" dark>mdi-update</v-icon>
        <v-layout column>
          <div>
            <strong>Updates have been successfully downloaded...</strong>
          </div>
          <div>
            <span>
              {{ getAppName }} will automatically refresh once the update is
              complete.
            </span>
          </div>
        </v-layout>
      </v-layout>
      <template v-slot:action>
        <v-container fluid class="pr-3">
          <v-progress-circular indeterminate size="28"></v-progress-circular>
        </v-container>
      </template>
    </v-snackbar>
  </v-overlay>

  <!-- User-initiated install -->
  <v-snackbar
    v-else
    :value="updateExists"
    color="primary"
    multi-line
    timeout="-1"
    bottom
    class="pb-7"
    rounded="lg"
  >
    <v-layout align-center justify-space-around>
      <v-icon class="pr-3" dark>mdi-update</v-icon>
      <v-layout column>
        <div>
          <strong>Update Available</strong>
        </div>
        <div>
          <span>
            Please install the newest version of
            <span v-html="getAppName"></span> to get the latest updates.
          </span>
        </div>
      </v-layout>
    </v-layout>
    <template v-slot:action>
      <v-btn icon @click="refreshApp" :loading="willRefresh" class="pr-3">
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
// Libraries
import { mapGetters } from "vuex";
import update from "@/mixins/update";

export default {
  name: "UpdateAlert",
  data: () => ({}),
  props: ["autoInstall"],
  mixins: [update],
  computed: {
    ...mapGetters(["getAppName"]),
  },
};
</script>
