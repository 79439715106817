import colors from "vuetify/lib/util/colors";

const state = {
  eventScheduleTypes: [
    {
      text: "In-Store",
      desc: "Requires a date/time + brand rep & promotion",
      value: "inStore",
    },
    {
      text: "Ghost Promo",
      desc: "Run by the Account. No brand rep/time is needed, but requires date/promotion.",
      value: "ghost",
    },
    {
      text: "Brand Presence",
      desc: "Similar to In-Store, but no promotion.",
      value: "presence",
    },
  ],
  eventStatus: [
    {
      text: "Complete",
      value: "complete",
      color: "#33cc00",
      desc: "",
      sort: 7,
    },
    {
      text: "Ready for review",
      value: "ready_for_review",
      color: "#33ccff",
      desc: "A report from the event has been submitted, an event manager needs to complete a final review of the results.",
      sort: 6,
    },
    {
      text: "Pending report",
      value: "pending_report",
      color: "#3333ff",
      desc: "Event has been confirmed, a final report is still pending.",
      sort: 5,
    },
    {
      text: "Inventory Confirmation",
      value: "client_confirmation",
      color: "#ffcc66",
      desc: "Confirm with the account that inventory is available & this event's date/time are confirmed.",
      sort: 4,
    },
    {
      text: "Rep Confirmation",
      value: "rep_confirmation",
      color: "#cc9933",
      desc: "The rep assigned to this event needs to confirm their availability before moving to the next step.",
      sort: 3,
    },
    {
      text: "Reassign Rep",
      value: "reassign",
      color: colors.shades.black,
      desc: "This store event needs to be reassigned to a 3rd party brand ambassador",
      sort: 2,
    },
    {
      text: "Approval Required",
      value: "approval",
      color: "#808080",
      desc: "A promotion needing approval from a manager has been selected before this event can continue.",
      sort: 1,
    },
    {
      text: "Cancelled",
      value: "cancelled",
      color: "#ff0000",
      desc: "",
      sort: 0,
    },
  ],
  promoTypes: [
    {
      text: "Product",
      desc: "Select which product categories will apply to this discount",
      value: "product",
    },
    {
      text: "Percentage Match",
      desc: "Set the brand / store percentage amount",
      value: "percentage",
    },
  ],
  cancelReasons: [
    {
      text: "Account Cancelled",
      desc: "The account has decided to cancel this event. If date/time has changed, this can be updated through 'Store Event - Modify'",
      icon: "mdi-folder-account",
      value: "store",
    },
    {
      text: "Insufficient Inventory",
      desc: "Store did not have enough inventory once the brand rep arrived at the store for this event",
      icon: "mdi-package-variant",
      value: "inventory",
    },
    {
      text: "Brand Cancelled",
      desc: "Brand (you) have cancelled this store event",
      icon: "mdi-store",
      value: "brand",
    },
    {
      text: "Rep not available",
      desc: "The assigned brand rep for this event is not available & a replacement rep cannot be set in time for this event",
      icon: "mdi-account-alert",
      value: "user",
    },
  ],
  footTraffic: [
    {
      text: "Low",
      value: "low",
      desc: "1-20",
    },
    {
      text: "Medium",
      value: "med",
      desc: "21-50",
    },
    {
      text: "High",
      value: "high",
      desc: "50+",
    },
  ],
  shelfStock: [
    {
      text: "Low",
      value: "low",
      color: colors.red.base,
    },
    {
      text: "Medium",
      value: "med",
      color: colors.orange.base,
    },
    {
      text: "High",
      value: "high",
      color: colors.green.base,
    },
  ],
  percentageDiscounts: [
    { text: "10%", value: 10 },
    { text: "15%", value: 15 },
    { text: "20%", value: 20 },
    { text: "25%", value: 25 },
  ],
  hours: [
    {
      text: "12",
      value: 12,
    },
    {
      text: "1",
      value: 1,
    },
    {
      text: "2",
      value: 2,
    },
    {
      text: "3",
      value: 3,
    },
    {
      text: "4",
      value: 4,
    },
    {
      text: "5",
      value: 5,
    },
    {
      text: "6",
      value: 6,
    },
    {
      text: "7",
      value: 7,
    },
    {
      text: "8",
      value: 8,
    },
    {
      text: "9",
      value: 9,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "11",
      value: 11,
    },
  ],
  minutes: [
    {
      text: "00",
      value: "00",
    },
    {
      text: "15",
      value: "15",
    },
    {
      text: "30",
      value: "30",
    },
    {
      text: "45",
      value: "45",
    },
  ],
  timeOfDay: [
    {
      text: "AM",
      value: "am",
    },
    {
      text: "PM",
      value: "pm",
    },
  ],
  duration: [
    {
      text: "1",
      value: 1,
    },
    {
      text: "2",
      value: 2,
    },
    {
      text: "3",
      value: 3,
    },
    {
      text: "4",
      value: 4,
    },
    {
      text: "5",
      value: 5,
    },
    {
      text: "6",
      value: 6,
    },
    {
      text: "7",
      value: 7,
    },
    {
      text: "8",
      value: 8,
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getPromoTypes(state) {
    return state.promoTypes;
  },
  getEventCancelReasons(state) {
    return state.cancelReasons;
  },
  getPercentageDiscounts(state) {
    return state.percentageDiscounts;
  },
  getEventTypes(state) {
    return state.eventScheduleTypes;
  },
  getEventStatus(state) {
    return state.eventStatus;
  },
  getEventFootTraffic(state) {
    return state.footTraffic;
  },
  getEventShelfStock(state) {
    return state.shelfStock;
  },
  getEventHours(state) {
    return state.hours;
  },
  getEventMinutes(state) {
    return state.minutes;
  },
  getEventTimeOfDay(state) {
    return state.timeOfDay;
  },
  getEventDuration(state) {
    return state.duration;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
